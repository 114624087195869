import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'vex-common-autocomplete',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './common-autocomplete.component.html',
  styleUrls: ['./common-autocomplete.component.scss']
})
export class CommonAutocompleteComponent {
  @Input() search: FormControl;
  @Input() filteredValue: Observable<any>;
  @Input() label: string;
  @Input() valueList: any;
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedValueId: any = '';
  @Input() showLabel: boolean = true;
  @Input() placeholder: string = 'Search...';
  initialValue: any = '';
  ngOnInit() {
    if (this.selectedValueId != '' && this.selectedValueId != null) {
      const selectedValue = this.valueList.find(
        (value: any) => value.id == this.selectedValueId
      );
      this.initialValue = selectedValue ? selectedValue?.name : '';
    }
  }

  onSearch(event: any) {
    let name = event.target.value.toLowerCase();
    this.filteredValue = of(
      this.valueList.filter((i: any) =>
        i.name.toLowerCase().includes(name.toLowerCase())
      )
    );
  }

  selectedOptions(event: any) {
    this.optionSelected.emit(event);
  }

  displayVl(option: any) {
    return option ? option.name : '';
  }
}
