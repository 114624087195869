<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    {{ cmsData[0].name }}
  </h2>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content>
  <div class="text-wrap pt-4" [innerHTML]="cmsData[0].description"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="!isChecked" mat-button mat-dialog-close type="button">
    Cancel
  </button>
  <button
    (click)="onSubmit()"
    *ngIf="isChecked"
    color="primary"
    mat-raised-button
    matStepperNext>
    Agree
  </button>
</mat-dialog-actions>
