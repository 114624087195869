import {
  AfterViewInit,
  Component,
  DestroyRef,
  inject,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { merge, Observable, of as observableOf, of, ReplaySubject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil
} from 'rxjs/operators';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  MatPaginator,
  MatPaginatorIntl,
  MatPaginatorModule
} from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { stagger40ms } from '@vex/animations/stagger.animation';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl
} from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import {
  MatCheckboxChange,
  MatCheckboxModule
} from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { VexPageLayoutContentDirective } from '@vex/components/vex-page-layout/vex-page-layout-content.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexPageLayoutHeaderDirective } from '@vex/components/vex-page-layout/vex-page-layout-header.directive';
import { VexPageLayoutComponent } from '@vex/components/vex-page-layout/vex-page-layout.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FilterColumnsComponent } from '../filter-columns/filter-columns.component';
import { ViewPdfComponent } from 'src/app/pages/master-data/program-config/components/view-pdf/view-pdf.component';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { DateInputFormatDirective } from 'src/app/directives/date-input.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonAutocompleteComponent } from '../common-autocomplete/common-autocomplete.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DateInputFilterComponent } from '../../../core/shared/date-input-filter/date-input-filter.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserAuthenticationService } from 'src/app/core/navigation/user-authantication.service';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss'],
  animations: [fadeInUp400ms, stagger40ms],
  standalone: true,
  imports: [
    VexPageLayoutComponent,
    VexPageLayoutHeaderDirective,
    VexBreadcrumbsComponent,
    MatButtonToggleModule,
    ReactiveFormsModule,
    VexPageLayoutContentDirective,
    NgIf,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    NgFor,
    NgClass,
    MatPaginatorModule,
    FormsModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    DateInputFormatDirective,
    MatDatepickerModule,
    CommonAutocompleteComponent,
    DateInputFilterComponent,
    MatProgressSpinnerModule
  ]
})
export class CommonTableComponent implements OnInit, AfterViewInit {
  layoutCtrl = new UntypedFormControl('boxed');
  toggleCtrl = new UntypedFormControl('Active');
  toggleArchiveListCtrl = new UntypedFormControl('active');
  toggleCQACompanyListCtrl = new UntypedFormControl('cqa');
  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */

  public subject$: ReplaySubject<QueryResultsModel> =
    new ReplaySubject<QueryResultsModel>();
  public data$: Observable<QueryResultsModel> = this.subject$.asObservable();

  public filteredAndPagedIssues: Observable<any[]> = of([]);
  // allSelected: boolean = false;
  public resultsLength = 0;
  public isLoadingResults = true;
  public isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(
    new MatPaginatorIntl(),
    ChangeDetectorRef.prototype
  );
  @ViewChild('archivedToggle', { static: false })
  archivedToggle!: MatSlideToggle;
  defaultImageUrl = '/assets/images/product inline.png';
  hideIcons = true;
  selectAllArray: any[] = [];
  selectAllChecked: boolean = false;
  eventCheckedStatusTrue: boolean = false;
  @Input() selectedData: any[] = [];

  @ViewChild(MatSort) sort: MatSort;

  @Input() userAddItems: boolean = false;
  @Input() columns: TableColumn<any>[] = [];
  @Input() moreButtons: Array<any> = [];
  @Input() tableConfig = {
    showSearch: true,
    showPagination: true,
    actionFields: true,
    showDateRange: false,
    showDateFilter: false,
    showStaffFilter: false,
    showOrderTypeFilter: false,
    showPlatformFilter: false,
    isAddButton: false,
    isExportButton: false
  };
  @Input() showDateRange: boolean = false;
  @Input() showMultiDateRange: boolean = false;
  @Input()
  emptyState = {
    title: ''
  };
  @Input() title = '';
  @Input() crumbsMenu = 'Dashboard';
  @Input() deliveryTitle = '';
  @Input() emptyTitle = '';
  @Input() upperCaseTitle = '';
  @Input() showInventoryTitle = false;
  @Input() showEmptyPageAddBtn = true;
  @Input() showSelectArea = false;
  @Input() showEmptyPage: boolean = true;

  @Input() dispId = '';
  @Input() actions: ActionsInterface[] = [];
  @Input() burgerActions: Array<any> = [];
  @Input() tableActions: Array<any> = [];
  @Input() showReset = false;
  @Input() showAddButton = false;
  @Input() showBulkButton = false;
  @Input() showActionBtn = false;
  @Input() showSettings = true;
  @Input() showClearBtn = false;
  @Input() showPayOutButton = false;
  @Input() showTransferButton = false;
  @Input() showPayInButton = false;
  @Input() showExportButton = false;
  @Input() showPdfButton = false;
  @Input() showProductTypeFilter = false;
  @Input() showRoomTypeFilter = false;
  @Input() showBrandedTypeFilter = false;
  @Input() showFilterBtn = false;
  @Input() filterDropdown: boolean = false;

  @Input() showProductCategoryList = false;
  @Input() showVendorList = false;
  @Input() vendorList = [];
  @Input() inventoryStatus = [];
  @Input() showInventoryStatus = false;
  @Input() showCheckInType = false;
  @Input() dispensaryList = [];

  @Input() showFinishUnFinish = false;
  @Input() showDispensaryList = false;

  @Input() showInventoryActions = false;
  @Input() showInventoryBulkActions = false;
  @Input() showRoleFilter = false;
  @Input() showBlockToggle = false;
  @Input() showExportExcelSheetButton = false;
  @Input() showArchivedToggle = false;
  @Input() productCategoryList = [];
  @Input() cashTransaction = false;
  @Input() selectionClear: boolean = false;
  @Input() childActiveRoute: string = '';

  @Input() showActiveInActive: boolean = false;
  @Input() showWidthFullWidth: boolean = true;
  @Input() showBreadCrumbs: boolean = true;
  @Input() enablePadding: boolean = true;
  @Input() showActiveArchiveList: boolean = false;
  @Input() enableInfoIcon: boolean = false;
  @Input() showCQACompanyList: boolean = false;
  @Input() toggleViewString: string = 'CQA';
  @Input() dateRangeFormList: any[];
  @Output() onLoadItemsEvent = new EventEmitter();
  @Output() onLoadProductEvent = new EventEmitter();

  @Output() onActionClickEmit = new EventEmitter();
  @Output() onSelectAllDatas = new EventEmitter();
  @Output() onSelectRowDatas = new EventEmitter();
  @Output() onLinkClickEmit = new EventEmitter();

  @Output() onAddRow = new EventEmitter();
  @Output() onAddClick = new EventEmitter();
  @Output() onBulkCreateClick = new EventEmitter();
  @Output() onExportExcelSheet = new EventEmitter();
  @Output() openMoveInventoryModal = new EventEmitter();
  @Output() onExportClick = new EventEmitter();
  @Output() onPdfClick = new EventEmitter();

  @Output() onStatusChange = new EventEmitter();
  @Output() onDateRangeChange = new EventEmitter();
  @Output() onStaffChange = new EventEmitter();
  @Output() onProductType = new EventEmitter();
  @Output() onRoomType = new EventEmitter();
  @Output() onCheckInType = new EventEmitter();
  @Output() onFinishUnFinish = new EventEmitter();

  @Output() onchangeProductCategory = new EventEmitter();

  @Output() onChangeInventoryStatus = new EventEmitter();

  @Output() onChangeDispensaryList = new EventEmitter();

  @Output() onChangeVendor = new EventEmitter();

  @Output() onOrderTypeChange = new EventEmitter();

  @Output() onPlatformChange = new EventEmitter();
  @Output() onArchivedToggle = new EventEmitter();
  @Output() onResetClick = new EventEmitter();
  @Output() onDateFilterChange = new EventEmitter();
  @Output() onMoreClick = new EventEmitter();
  @Output() onActionHoverEmit = new EventEmitter();
  @Output() onActionPrintEmit = new EventEmitter();
  @Output() onChangeSampleType = new EventEmitter();

  apiKeyVisibility: { [key: string]: boolean } = {};

  public queryResults?: QueryResultsModel;

  public userAddItems1: any;

  totalData?: any[];
  totalLength?: number;
  currentPageRows?: any[];
  numRows?: number;
  public activeRoute? = '';

  pageSize = 50;
  pageSizeOptions: number[] = [50, 100, 150, 200];
  public dataSource!: MatTableDataSource<any> | null;
  public selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @Output() onActiveInactive = new EventEmitter();
  @Output() onActiveArchiveList = new EventEmitter();
  @Output() onCQACompanyList = new EventEmitter();
  deleteIcon: boolean;
  infoIcon: boolean;
  filterButtonText = 'Filter';
  filterButtonIcon = 'mat:filter_alt';
  showFilter = false;
  @Input() sampleTypeList: any[] = [];
  @Input() showSampleTypeFilter: boolean = false;
  @Input() programList: any[] = [];
  @Input() showProgramFilter: boolean = false;
  @Output() onChangeProgram = new EventEmitter();

  @Input() statusList: any[] = [
    { name: 'Pending', value: 'Pending' },
    { name: 'On Hold', value: 'on-hold' },
    { name: 'Completed', value: 'completed' }
  ];
  @Input() paymentStatusList: any[] = [
    { name: 'Initiated', value: 'PaymentInitiated' },
    { name: 'Completed', value: 'PaymentCompleted' }
  ];
  @Input() paymentMethodList: any[] = [
    { name: 'Card', value: 'card' },
    { name: 'ACH', value: 'ach' },
    { name: 'Wire Transfer', value: 'wire-transfer' }
  ];
  @Input() showStatusFilter: boolean = false;
  @Output() onChangeSampleStatus = new EventEmitter();
  @Input() showPaymentStatusFilter: boolean = false;
  @Output() onChangePaymentStatus = new EventEmitter();
  @Input() showPaymentMethodStatusFilter: boolean = false;
  @Output() onChangePaymentMethodStatus = new EventEmitter();

  @Input() companyList: any[] = [];
  @Input() showCompanyFilter: boolean = false;
  @Output() onChangeCompany = new EventEmitter();
  public datePickerForm: FormGroup;
  @Input() showStatusBadgeFilter: boolean = false;
  @Input() badgeFilterItems: any[] = [];
  activeItem: string | null = 'all';
  @Output() onBadgeFilterEmit = new EventEmitter();
  @Output() onMultipleDateFilterEmit = new EventEmitter();
  @Input() showAddOptionButton = false;
  @Input() showAddOptionButtonValues: any[];
  @Output() onAddOptionClick = new EventEmitter();
  @Input() isLoading: boolean = false;
  @Output() onBtnActionClick = new EventEmitter();

  @Input() showActiveInActiveObj: any = {
    active: 'Active',
    inactive: 'Inactive',
    activeCount: '',
    inactiveCount: ''
  };
  @Input() routerMerge: string | null = '';
  constructor(
    private _httpClient: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private route: ActivatedRoute,
    public userCheck: UserAuthenticationService
  ) {}

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  ngOnInit() {
    if (this.showStatusBadgeFilter == true) {
      this.router.navigate([], {
        queryParams: { status: this.badgeFilterItems[0].value }
      });
    }
    this.datePickerForm = new FormGroup({
      start: new FormControl(),
      end: new FormControl()
    });
    this.userAddItems1 = this.userAddItems;

    this.activeRoute = this.childActiveRoute
      ? this.childActiveRoute
      : this.router.url.split('/').pop();

    let data = localStorage.getItem(this.router.url);

    if (data) this.columns = JSON.parse(data);

    this.dataSource = new MatTableDataSource();
    this.searchCtrl.valueChanges
      .pipe(
        // untilDestroyed(this),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((value) => {
        this.showEmptyPage = false;
        this.paginator.pageIndex = 0;
        this.router.navigate([], {
          queryParams: { search: value },
          queryParamsHandling: 'merge'
        });
        this.initLoadItems();
      });
    this.filteredAndPagedIssues.subscribe((data) => {
      this.totalData = data;

      this.totalLength = data.length;
    });

    // this.toggleCtrl.valueChanges.subscribe((i) => {
    //   this.onActiveInactive.emit(i);
    // });
    this.toggleArchiveListCtrl.valueChanges.subscribe((i) => {
      this.onActiveArchiveList.emit(i);
    });
    this.toggleCQACompanyListCtrl.valueChanges.subscribe((i) => {
      this.onCQACompanyList.emit(i);
    });
    if (this.enableInfoIcon == true) {
      this.deleteIcon = false;
      this.infoIcon = true;
    } else {
      this.deleteIcon = true;
      this.infoIcon = false;
    }

    this.datePickerForm.valueChanges
      .pipe(debounceTime(200))
      .subscribe((event) => {
        if (event.start && event.end) {
          this.changeDate(event);
        } else if (!event.start && !event.end) {
          this.changeDate(null);
        }
      });

    if (this.showActiveInActive) {
      const pageIndex = this.paginator ? this.paginator.pageIndex : 0;
      this.router.navigate([], {
        queryParams: { isActive: 'Active' },
        queryParamsHandling: 'merge'
      });
    }
    this.toggleCtrl.valueChanges
      .pipe(debounceTime(20), takeUntilDestroyed(this.destroyRef))
      .subscribe((isActive) => {
        // const pageIndex = this.paginator ? this.paginator.pageIndex : 0;
        this.router.navigate([], {
          queryParams: { isActive },
          queryParamsHandling: 'merge'
        });
      });

    //based on query parameter changes update form control and emit event
    this.route.queryParams
      .pipe(debounceTime(20), takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        const isActive = params['isActive'];
        const status = params['status'];
        const page = params['page'];

        if (this.paginator && page) {
          this.paginator.pageIndex = page - 1;
        } else {
          this.paginator.pageIndex = 0;
        }
        if (isActive && this.showActiveInActive) {
          this.toggleCtrl.setValue(isActive);
          this.onActiveInactive.emit(isActive);
        } else if (this.paginator && page) {
          this.initLoadItems();
        } else if (this.paginator && !page) {
          this.paginator.pageIndex = 0;
        }
        if (status) {
          this.activeItem = status;
        }
      });
    this.initLoadItems(true);
  }

  ngAfterViewInit() {
    merge(this.sort.sortChange, this.paginator?.page)
      .pipe(startWith())
      .subscribe((resp) => {
        if (resp) {
          if (!this.showActiveInActive) {
            this.initLoadItems();
          }
          const pageIndex = this.paginator ? this.paginator.pageIndex : 0;
          this.router.navigate([], {
            queryParams: { page: pageIndex + 1 },
            queryParamsHandling: 'merge' // This will merge new query params with existing ones
          });
        }
      });

    this.filteredAndPagedIssues = this.data$.pipe(
      startWith({ totalCount: 0, items: [] }),
      map((data) => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data.totalCount;
        for (const row of data.items) {
          // Assuming 'uniqueIdentifier' is the property that uniquely identifies each row
          const rowKey = row.api_key; // Replace with your actual property name
          this.apiKeyVisibility[rowKey] = true;
        }
        return data.items;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    );
  }

  /**
   * Load items
   *
   * @param firstLoad: boolean
   */

  initLoadItems(firstLoad: boolean = false) {
    const pageIndex = this.paginator ? this.paginator.pageIndex : 0;
    const pageSize = this.paginator ? this.paginator.pageSize : 0;

    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort ? this.sort.direction.toUpperCase() : 'DESC',
      this.sort ? this.sort.active : 'id',
      pageIndex,
      firstLoad ? 50 : pageSize,
      this.searchCtrl ? this.searchCtrl.value : ''
    );
    let queryResults = {
      items: [],
      totalCount: 0,
      error: ''
    };
    this.subject$.next(queryResults);
    this.isLoading = true;
    this.onLoadItemsEvent.emit(queryParams);
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): any {
    const filter: any = {};
    return filter;
  }

  resetPaging(): void {
    // this.paginator.pageIndex = 0;
  }

  addRowValue(row: any) {
    this.onAddRow.emit(row);
  }

  updateSelectedData() {
    // if (this.selectedData) {
    this.filterDropdown = true;
    this.showActionBtn = true;
    this.selectedData = this.selection.selected;

    // this.selectedData = this.selection.selected;
    // }
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLinkClick(data: any) {
    this.onLinkClickEmit.emit({ data });
  }

  onActionClick(action: any, data: any) {
    this.onActionClickEmit.emit({ action, data });
  }

  onActionPrint(action: any, data: any) {
    this.onActionPrintEmit.emit({ action, data });
  }

  onActionHover(event: any, action: any, data: any, isHovering: any) {
    this.onActionHoverEmit.emit({ event, action, data, isHovering });
  }

  public changeStatus(event: any, row: any, column: any) {
    row[column.property] = !row[column.property]; // Toggle the state

    this.onStatusChange.emit({ status: row[column.property], row: row });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource?.data.forEach((row) => this.selection.select(row));
  }

  toggleAllRows() {
    const pageIndex = this.paginator?.pageIndex;
    const pageSize = this.paginator?.pageSize;
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    this.filteredAndPagedIssues.subscribe((data) => {
      this.totalData = data;
      this.totalLength = data.length;
    });
    this.currentPageRows = this.totalData?.slice(start, end);
    let numSelected = this.selection.selected.length;
    let numRows = this.totalData?.length;
    this.numRows = this.currentPageRows?.length;

    if (numSelected === numRows) {
      numSelected = 0;
      numRows = 0;
      this.selectedData.length = 0;
      this.filterDropdown = false;
      this.showActionBtn = false;

      this.selection.clear();

      this.totalData?.forEach((row) => this.selection.deselect(row));
    } else {
      this.selection.clear();
      this.filterDropdown = true;
      this.showActionBtn = true;
      this.totalData?.forEach((row) => this.selection.select(row));
      this.selectedData = this.selection.selected;
    }
  }

  onLabelChange(change: MatSelectChange, row: any) {
    // const index = this.rows.findIndex(c => c === row);
    // this.rows[index].labels = change.value;
    // this.subject$.next(this.rows);
  }

  toggleColumnVisibility(column: any, event: any) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  filterBurgerActions(row: any): any[] {
    let filteredActions = this.burgerActions;
    // Filter out 'Approve' action if row.isApproved is 1
    if (!row.isVerified && !row.Active) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'approveCompany' &&
          item.key !== 'suspend' &&
          item.key !== 'reactivate'
      );
    } else if (row.companyRejected) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'approveCompany' &&
          item.key !== 'suspend' &&
          item.key !== 'reactivate'
      );
    } else if (row.isApproved == 1) {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'approveCompany'
      );

      if (row.isSuspended) {
        filteredActions = filteredActions.filter(
          (item) => item.key !== 'suspend'
        );
      } else {
        filteredActions = filteredActions.filter(
          (item) => item.key !== 'reactivate'
        );
      }
    } else {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'decline'
      );
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'reactivate'
      );
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'suspend'
      );
    }

    if (row.stripeConfigured == 'Success') {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'stripeConfig'
      );
    } else {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'stripeConfigDelete'
      );
    }

    if (row.quotation != 'Yes') {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'quotationForm'
      );
    }

    if (row.isRejected == true) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'accept' &&
          item.key !== 'pay' &&
          item.key !== 'viewPdfInvoice' &&
          item.key !== 'deactivate' &&
          item.key !== 'activate' &&
          item.key !== 'cancelPay' &&
          item.key !== 'terminateAgreement' &&
          item.key !== 'approve'
      );
    } else if (row.isPaid && row.orderId) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'accept' &&
          item.key !== 'approve' &&
          item.key !== 'pay' &&
          item.key !== 'activate' &&
          item.key !== 'agreementReactivate' &&
          item.key !== 'agreementFormResubmit'
      );
    } else if (row.isFinalized && row.orderId) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'accept' &&
          item.key !== 'approve' &&
          item.key !== 'viewPdfInvoice' &&
          item.key !== 'deactivate' &&
          item.key !== 'activate' &&
          item.key !== 'cancelPay' &&
          item.key !== 'terminateAgreement' &&
          item.key !== 'agreementReactivate' &&
          item.key !== 'agreementFormResubmit'
      );
    } else if (row.isApprove) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'approve' &&
          item.key !== 'pay' &&
          item.key !== 'viewPdfInvoice' &&
          item.key !== 'deactivate' &&
          item.key !== 'activate' &&
          item.key !== 'cancelPay' &&
          item.key !== 'terminateAgreement' &&
          item.key !== 'accept' &&
          item.key !== 'agreementReactivate' &&
          item.key !== 'agreementFormResubmit'
      );
    } else if (row.isFormSubmitted) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'accept' &&
          item.key !== 'pay' &&
          item.key !== 'viewPdfInvoice' &&
          item.key !== 'deactivate' &&
          item.key !== 'activate' &&
          item.key !== 'cancelPay' &&
          item.key !== 'terminateAgreement' &&
          item.key !== 'agreementReactivate' &&
          item.key !== 'agreementFormResubmit'
      );
    } else if (row.RenewedRequest) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'accept' &&
          item.key !== 'pay' &&
          item.key !== 'viewPdfInvoice' &&
          item.key !== 'deactivate' &&
          item.key !== 'activate' &&
          item.key !== 'cancelPay' &&
          item.key !== 'terminateAgreement' &&
          item.key !== 'approve'
      );
    } else if (row.isAccepted) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'accept' &&
          item.key !== 'pay' &&
          item.key !== 'viewPdfInvoice' &&
          item.key !== 'deactivate' &&
          item.key !== 'activate' &&
          item.key !== 'cancelPay' &&
          item.key !== 'terminateAgreement' &&
          item.key !== 'approve' &&
          item.key !== 'agreementReactivate' &&
          item.key !== 'agreementFormResubmit'
      );
    } else if (!row.isAccepted) {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'approve' &&
          item.key !== 'pay' &&
          item.key !== 'viewPdfInvoice' &&
          item.key !== 'deactivate' &&
          item.key !== 'activate' &&
          item.key !== 'cancelPay' &&
          item.key !== 'terminateAgreement' &&
          item.key !== 'agreementReactivate' &&
          item.key !== 'agreementFormResubmit' &&
          item.key !== 'viewAgreement'
      );
    }
    if (row.isActive !== 'Active') {
      filteredActions = filteredActions.filter(
        (action) => action.key !== 'addContact'
      );
    }
    if (row.formType !== 'Interactive') {
      filteredActions = filteredActions.filter(
        (action) => action.key !== 'onViewFormFields'
      );
    }
    if (row.isDeclined) {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'approve'
      );
    }

    if (!row.ifc06) {
      filteredActions = filteredActions.filter((item) => item.key !== 'ifc06');
    }
    if (!row.processFlowInstanceDetails) {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'viewProcess'
      );
    }

    if (!row.isIFC05) {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'SampleRec'
      );
    }

    if (row.isFormVariant) {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'view' && item.key !== 'download'
      );
    } else {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'onViewFormVariants'
      );
    }
    if (row.BrandApprove != 'Yes') {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'downloadCertificate' && item.key !== 'fileCertificate'
      );
    }
    if (row.LabelApprove != 'Yes') {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'brandCertificate' &&
          item.key !== 'brandCertificateDownload'
      );
    }

    if (row.uploadToWebsite == 'unPublished') {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'brandUnpublish'
      );
    }
    if (row.uploadToWebsite == 'Published') {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'brandPublish'
      );
    }
    // if (!row.SampleRecDate) {
    //   filteredActions = filteredActions.filter(
    //     (item) => item.key !== 'SampleRec'
    //   );
    // }
    if (row.isFieldAudit) {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'paymentStatus' && item.key != 'viewProcess'
      );
    }
    if (row.PONumber == null) {
      // filteredActions = filteredActions.filter(
      //   (item) => item.key != 'quatCart'
      // );

      if (row.paymentStatus == 'Completed') {
        filteredActions = filteredActions.filter(
          (item) => item.key != 'payInvoicePaymentList'
        );
      }
      if (row.paymentStatus == 'Completed') {
        filteredActions = filteredActions.filter(
          (item) => item.key != 'InvoicePay'
        );
      }
      filteredActions.forEach((i) => {
        if (i.key == 'quatPONumber') {
          i.label = 'Enter PO Number';
        }
      });
    }

    if (row.is_deleted == false) {
      filteredActions = filteredActions.filter(
        (item) => item.key != 'userReactivate'
      );
    }

    if (row.is_deleted == true) {
      filteredActions = filteredActions.filter(
        (item) => item.key == 'userReactivate'
      );
    }

    if (!row.menuRoleId) {
      filteredActions = filteredActions.filter(
        (item) => item.key != 'permissions'
      );
    }

    return filteredActions;
  }

  filterActionButtons(row: any) {
    let filteredActions = this.actions;
    if (row.quotation != 'Yes') {
      filteredActions = filteredActions.filter(
        (item) => item.property !== 'quotationForm'
      );
    }
    return filteredActions;
  }

  public loadItems(queryResults: QueryResultsModel) {
    this.queryResults = queryResults;
    this.subject$.next(queryResults);
    this.isLoading = false;
  }

  public loadExportItems() {
    const data = [];
    if (this.queryResults) {
      for (const row of this.queryResults.items) {
        const temp: any = {};
        for (const colum of this.columns) {
          if (colum.property !== 'actions') {
            temp[colum.label] = row[colum.property] ? row[colum.property] : '';
          }
        }
        data.push(temp);
      }
    }

    this.appSettingsService.exportCSV(data, this.title);
  }

  isAllSelecteds() {
    const numSelected = this.selection.selected?.length;
    const pageIndex = this.paginator?.pageIndex;
    const pageSize = this.paginator?.pageSize;
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    const currentPageRows = this.totalData?.slice(start, end);
    const numRows = currentPageRows?.length;

    return numSelected === numRows;
  }

  onSelectRowData(data: any, event: MatCheckboxChange) {
    this.eventCheckedStatusTrue = event.checked;

    this.onSelectRowDatas.emit({
      data: [data],
      eventCheckedStatus: event.checked
    });
  }
  onSelectAllRow() {
    this.filteredAndPagedIssues.subscribe((data) => {
      this.totalData = data;
      this.totalLength = data.length;
      this.onSelectAllDatas.emit({
        data: this.totalData,
        length: this.totalLength,
        selectAllChecked: this.selectAllChecked
      });
    });

    this.selectAllChecked = !this.selectAllChecked;
  }

  getTextColor(value: any, row = null) {
    let clas = '';
    let isDefault: boolean = false;
    switch (value) {
      case 'Delivered':
      case 'accepted':
      case 'Active':
      case 'Approved':
      case 'Both':
      case 'global':
      case 'Patient':
      case 'Store':
      case 'Adult':
      case 'Amount':
      case 'Yes':
      case 'Metrc':
      case 'Sale':
      case 'Received':
      case 'Close':
      case 'Cash':
      case 'Finalized':
      case 'true':
      case 'Paid':
      case 'Success':
      case 'CQA Admin':
      case 'Completed':
      case 'Informative':
        clas = 'text-green-600 bg-green-600/10';
        break;
      case 'Placed':
      case 'created':
      case 'Debit':
      case 'Pay-In':
      case 'Pending':
      case 'Percentage':
      case 'Push Notification':
      case 'PO':
      case 'Moved':
      case 'Fulfillment':
      case 'PaymentInitiated':
      case 'CQA Staff':
      case 'Process':
      case 'Form Submitted':
        clas = 'text-yellow-600 bg-yellow-600/10';
        break;
      case 'Credit Card':
      case 'Accepted':
        clas = 'text-[#000AFF] bg-[#E5E6FF]';
        break;
      case 'Wire':
      case 'Agreement Created':
        clas = 'text-[#1796B1] bg-[#E5FAFF]';
        break;
      case 'Company Admin':
      case 'Interactive':
        clas = 'text-blue-600 bg-blue-600/10';
        break;
      case 'ACH':
        clas = 'text-[#FF0099] bg-[#FFE5F4]';
        break;
      case 'Suspended':
        clas = 'text-gray-600 bg-gray-400/10';
        break;
      case 'Shipped':
      case 'Online':
      case 'Medical':
      case 'SMS':
      case 'Consumer':

      case 'Caregiver':
      case 'ExternalPatient':
      case 'Buy One Get One':
      case 'Adjusted':
      case 'Ready For Pickup':
      case 'Tip':
      case 'Credit':

      case 'Manual':
        clas = 'shipped';
        break;
      case 'Cancelled':
      case 'Voided':
      case 'Declined':
      case 'Expired':
      case 'Inactive':
      case 'Pay-Out':
      case 'Open':
      case 'false':
      case 'Destroyed':
      case 'No':
      case 'Terminated':
      case 'Rejected':
      case 'Failed':
        clas = 'text-red-600 bg-rose-600/10';
        break;
      case 'In transit':
      case 'support':
      case 'Redeemed':
      case 'location':
      case 'POS':
        clas = 'warning';
        break;
      case 'Not Ready For Sale':
        clas = 'not_ready_for_sale';
        break;
      case 'Ready For Sale':
        clas = 'ready_for_sale';
        break;

      case 'Closed':
        clas = 'close_badge';
        break;

      case 'Dispensary Admin':
        clas = 'dispensary_admin';
        break;
      case 'ACH / Wire':
        clas = 'text-[#FF0099] bg-[#FFE5F4]';
        break;
      case '-':
        clas = 'bg-transparent';
        break;
      case 'Manual':
        clas = 'text-blue-600 bg-blue-600/10';
        break;
      case 'Automatic':
        clas = 'text-orange-600 bg-orange-600/10';
        break;
      default:
        clas = '';
        isDefault = true;
        break;
    }
    if (isDefault) {
      clas = '';
    } else {
      clas = 'badge badge-success' + ' ' + clas;
    }
    return clas;
  }

  clearSelection() {
    this.selectionClear = true;
    this.selectedData = [];
    this.currentPageRows = [];
    this.numRows = 0;
    this.selectedData.length = 0;
    this.selection.clear();
  }

  /**
   * getDataByType
   */
  public getDataByType(row: any, column: any) {
    let data = '';

    switch (column.type) {
      case 'money':
        if (row[column.property] !== '-') {
          data = '$' + row[column.property];
        } else {
          data = row[column.property];
        }
        break;
      case 'date':
        data = row[column.property];
        break;
      case 'price':
        data = this.formatCurrency(Number(row[column.property]));

        break;
      default:
        data =
          row[column.property] !== '' &&
          row[column.property] !== null &&
          row[column.property] !== undefined
            ? row[column.property]
            : '-';

        break;
    }
    return data;
  }

  getChipColor(value: any, row = null) {
    let clas = '';
    switch (value) {
      case 'Delivered':
      case 'Active':
      case 'Approved':
      case 'Both':
      case 'Manual':
      case 'Automatic':
        clas = 'primary';
        break;
      case 'Placed':
      case 'created':
      case 'Pending':
      case 'location':
      case 'global':
      case 'Push Notification':
        clas = 'accent';
        break;
      case 'Shipped':
      case 'SMS':
        clas = 'secondary';
        break;
      case 'Cancelled':
      case 'Declined':
      case 'Inactive':
        clas = 'warn';
        break;
      case 'In transit':
      case 'support':
        clas = 'warn';
        break;
      default:
        break;
    }

    return clas;
  }

  private formatCurrency(value: number | null | undefined): string {
    if (value === null || value === undefined || isNaN(value)) {
      return '';
    }

    // console.log('value-price', value);
    const formattedValue = value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    // console.log('formattedValue-price', formattedValue);

    return formattedValue.replace('$', ' ');
  }

  onOpenSortColumnTable() {
    let dialogRef: MatDialogRef<FilterColumnsComponent>;
    dialogRef = this.dialog.open(FilterColumnsComponent, {
      width: '40%',
      data: {
        columns: this.columns,
        childActiveRoute: this.childActiveRoute
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {
      let data = localStorage.getItem(this.router.url);
      console.log(this.router.url);

      if (data) this.columns = JSON.parse(data);
    });
  }
  onCreate() {
    this.onAddClick.emit();
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    if (this.showFilter) {
      this.filterButtonText = 'Clear';
      this.filterButtonIcon = 'mat:close';
    } else {
      this.filterButtonIcon = 'mat:filter_alt';
      this.filterButtonText = 'Filter';
    }
    this.resetEventValues();
  }
  async viewFileData(title: any, ev: any) {
    let data = ev;
    if (!this.userCheck.checkUser()) {
      data = await this.appSettingsService.flattenPdfUrl(ev);
    }
    let dialogRef: MatDialogRef<ViewPdfComponent>;
    dialogRef = this.dialog.open(ViewPdfComponent, {
      width: '80%',
      data: {
        src: data,
        title: title
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {});
  }
  changeSampleType(event: MatSelectChange) {
    this.onChangeSampleType.emit(event.value);
  }
  changeProgram(event: MatSelectChange) {
    this.onChangeProgram.emit(event.value);
  }

  resetEventValues() {
    this.onChangeSampleType.emit({ type: '' });
    this.onChangeProgram.emit({ type: '' });
    this.onChangeSampleStatus.emit({ type: '' });
    this.onChangePaymentMethodStatus.emit({ type: '' });
    this.onChangePaymentStatus.emit({ type: '' });
    this.onChangeCompany.emit({ type: '' });
    this.onDateRangeChange.emit({ type: '' });
  }

  changeSampleStatus(event: MatSelectChange) {
    this.onChangeSampleStatus.emit(event.value);
  }
  changePaymentMethod(event: MatSelectChange) {
    this.onChangePaymentMethodStatus.emit(event.value);
  }
  changePaymentStatus(event: MatSelectChange) {
    this.onChangePaymentStatus.emit(event.value);
  }
  changeCompany(event: MatAutocompleteSelectedEvent) {
    this.onChangeCompany.emit(event.option.value);
  }
  onExport() {
    this.onExportClick.emit({ key: 'export', label: 'Export' });
    this.loadExportItems();
  }
  public changeDate(date: any) {
    this.onDateRangeChange.emit({ dates: date });
  }
  public changeMultipleDate(date: any) {
    this.onMultipleDateFilterEmit.emit({ dates: date });
  }

  onClickStatus(item: any) {
    if (this.activeItem === item.value) {
      this.activeItem = item.value;
    } else {
      this.activeItem = item.value;
    }
    // this.router.navigate([], { queryParams: { status:this.activeItem } })
    const pageIndex = this.paginator ? this.paginator.pageIndex : 0;
    const pageSize = this.paginator ? this.paginator.pageSize : 0;
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort ? this.sort.direction.toUpperCase() : 'DESC',
      this.sort ? this.sort.active : 'id',
      pageIndex,
      50,
      this.searchCtrl ? this.searchCtrl.value : ''
    );
    let queryResults = {
      items: [],
      totalCount: 0,
      error: ''
    };
    this.subject$.next(queryResults);
    this.isLoading = true;
    this.onBadgeFilterEmit.emit({ dates: item, queryParams: queryParams });
  }

  isActiveStatus(item: any): boolean {
    return this.activeItem === item;
  }

  /**
   * Downloads a PDF document and initiates the download process.
   *
   * @param ev - The event object triggered by the download action.
   * @returns void
   */
  async onDownload(ev: any): Promise<void> {
    if (!this.userCheck.checkUser()) {
      // Get the PDF data (ensure this returns the data you need)
      const data = await this.appSettingsService.flattenPdfUrl(ev);
      // Create a Blob from the data
      const blob = new Blob([data], { type: 'application/pdf' });
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = __filename; // Set the desired filename here
      document.body.appendChild(link);
      // Trigger a click on the link to start the download
      link.click();
      // Remove the link from the DOM
      document.body.removeChild(link);
      // Release the object URL
      window.URL.revokeObjectURL(link.href);
    } else {
      const fileUrl = ev;

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = 'file'; // You can set the desired filename here
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);
    }
  }

  onAddOptionCreate(ev: any) {
    this.onAddOptionClick.emit({ data: ev });
  }

  onBtnAction(ev: any) {
    this.onBtnActionClick.emit({ data: ev });
  }
  defaultUserImage: string = 'assets/img/icons/error-User.svg';
  handleImageError(event: any) {
    // Set the source of the image to the default image path
    event.target.src = this.defaultUserImage;
  }
}

export class QueryParamsModel {
  // fields
  filter: any;
  queryString: any;
  sortOrder: string; // asc || desc
  sortField: string;
  pageNumber: number;
  pageSize: number;

  // constructor overrides
  constructor(
    _filter: any,
    _sortOrder: string = 'asc',
    _sortField: string = '',
    _pageNumber: number = 0,
    _pageSize: number = 50,
    _queryString: any = ''
  ) {
    this.filter = _filter;
    this.queryString = _queryString;
    this.sortOrder = _sortOrder;
    this.sortField = _sortField;
    this.pageNumber = _pageNumber;
    this.pageSize = _pageSize;
  }
}

export class NewQueryParamsModel {
  active: boolean;
  sortOrder: string;
  queryString: string;
  sort: string; // asc || desc
  pageNumber: number;
  pageSize: number;
  filter?: string;

  // constructor overrides
  constructor(
    _active: boolean,
    _sortOrder: string = 'asc',
    _sort: string = 'id',
    _pageNumber: number = 0,
    _pageSize: number = 10,
    _queryString: string = ''
  ) {
    (this.active = _active), (this.sortOrder = _sortOrder);
    this.queryString = _queryString;
    this.sort = _sort;
    this.pageNumber = _pageNumber;
    this.pageSize = _pageSize;
  }
}

export class QueryResultsModel {
  // fields
  items: any[];
  totalCount: number;
  errorMessage?: string;

  constructor(
    _items: any[] = [],
    _totalCount: number = 0,
    _errorMessage: string = ''
  ) {
    this.items = _items;
    this.totalCount = _totalCount;
  }
}

export interface TableColumn<T> {
  label: string;
  property: keyof T | string | any;
  type:
    | 'id'
    | 'text'
    | 'mobile'
    | 'stock-quantity'
    | 'check-box'
    | 'image'
    | 'badge'
    | 'chip'
    | 'progress'
    | 'checkbox'
    | 'button'
    | 'status'
    | 'switch'
    | 'money'
    | 'price'
    | 'percentage'
    | 'date'
    | 'star'
    | 'status-badge'
    | 'barcode'
    | 'icon-btn'
    | 'link'
    | 'toggle'
    | 'description'
    | 'api_key'
    | 'productName'
    | 'metrcBadge'
    | 'file'
    | 'actions'
    | 'logo'
    | 'userLogo';
  visible?: boolean;
  remove?: boolean;
  cssClasses?: string[];
}

export interface ActionsInterface {
  key: string;
  property: string;
  label?: string;
  icon?: any;
}
