<mat-form-field class="w-full">
  <mat-label *ngIf="showLabel">{{ label }}</mat-label>
  <input
    type="text"
    matInput
    [matAutocomplete]="auto"
    (keyup)="onSearch($event)"
    [value]="initialValue"
    [placeholder]="placeholder" />
  <mat-icon matSuffix svgIcon="mat:search"></mat-icon>
  <mat-autocomplete
    (optionSelected)="selectedOptions($event)"
    #auto="matAutocomplete"
    [displayWith]="displayVl">
    <mat-option *ngFor="let option of filteredValue | async" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
