<vex-page-layout>
  <ng-container *ngIf="enablePadding">
    <div
      class="flex flex-col items-start justify-center cqa-page-layout-header gap-2"
      [class.pb-[3rem]]="enablePadding">
      <div
        [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-6]="layoutCtrl.value === 'fullwidth'"
        class="w-full flex flex-col sm:flex-row justify-between">
        <div *ngIf="showBreadCrumbs">
          <h1 class="title mt-0 mb-1 text-2xl font-bold">{{ title }}</h1>
          <vex-breadcrumbs [crumbs]="[crumbsMenu, title]"></vex-breadcrumbs>
        </div>

        <!-- <div class="hidden sm:block" *ngIf="showWidthFullWidth">
          <mat-button-toggle-group
            [formControl]="layoutCtrl"
            class="mt-4 sm:mt-0">
            <mat-button-toggle value="boxed">Boxed</mat-button-toggle>
            <mat-button-toggle value="fullwidth">Full-Width</mat-button-toggle>
          </mat-button-toggle-group>
        </div> -->
        <div class="hidden sm:block" *ngIf="showActiveInActive">
          <mat-button-toggle-group
            [formControl]="toggleCtrl"
            class="mt-4 sm:mt-0">
            <mat-button-toggle value="Active">Active</mat-button-toggle>
            <mat-button-toggle value="Inactive">Inactive</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="hidden sm:block" *ngIf="showActiveArchiveList">
          <mat-button-toggle-group
            [formControl]="toggleArchiveListCtrl"
            class="mt-4 sm:mt-0">
            <mat-button-toggle value="active">Active</mat-button-toggle>
            <mat-button-toggle value="archive">Archive</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="showCQACompanyList">
          <mat-button-toggle-group
            [formControl]="toggleCQACompanyListCtrl"
            class="mt-4 sm:mt-0">
            <mat-button-toggle value="cqa">{{
              toggleViewString
            }}</mat-button-toggle>
            <mat-button-toggle value="company">Company</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="showBrandToggleList">
          <mat-button-toggle-group
            [formControl]="toggleBrandToggleListCtrl"
            class="mt-4 sm:mt-0">
            <mat-button-toggle value="brands">Brands</mat-button-toggle>
            <mat-button-toggle value="report">Report</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="container h-9">
        <div
          *ngIf="showStatusBadgeFilter"
          class="flex flex-row items-center gap-2">
          <div *ngFor="let item of badgeFilterItems">
            <button
              mat-stroked-button
              [ngClass]="{
                '!bg-[#17aeff] !text-white !font-medium': isActiveStatus(
                  item.value
                ),
                '!bg-white !text-gray-400 !font-medium': !isActiveStatus(
                  item.value
                )
              }"
              (click)="onClickStatus(item)">
              <span> {{ item.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <vex-page-layout-content
    [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-6]="layoutCtrl.value === 'fullwidth'"
    [class.-mt-6]="enablePadding">
    <div class="overflow-auto" [class.-mt-16]="enablePadding">
      <div class="h-16 border-b sticky left-0 flex items-center">
        <h2
          *ngIf="selection.hasValue()"
          class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
          <span
            >{{ selection.selected.length + " " + title
            }}<span *ngIf="selection.selected.length > 1">s</span>
            selected</span
          >
        </h2>

        <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
          <!-- <button
            color="primary"
            mat-icon-button
            matTooltip="Delete selected"
            type="button">
            <mat-icon svgIcon="mat:delete"></mat-icon>
          </button> -->

          <!-- <button
            color="primary"
            mat-icon-button
            matTooltip="Another action"
            type="button">
            <mat-icon svgIcon="mat:folder"></mat-icon>
          </button> -->
        </div>

        <!-- <mat-form-field subscriptSizing="dynamic">
          <mat-icon matIconPrefix svgIcon="mat:search"></mat-icon>
          <input
            matInput
            [formControl]="searchCtrl"
            placeholder="Search&hellip;"
            type="text" />
        </mat-form-field> -->

        <!-- <mat-form-field subscriptSizing="dynamic">
          <input
            matInput
            [formControl]="searchCtrl"
            placeholder="Search&hellip;"
            type="text" />
          <mat-icon matIconSuffix svgIcon="mat:search"></mat-icon>
        </mat-form-field> -->

        <div class="search-form">
          <input
            type="text"
            class="form-control"
            name="email"
            [formControl]="searchCtrl"
            placeholder="Search&hellip;" />
          <span><mat-icon matIconSuffix svgIcon="mat:search"></mat-icon></span>
        </div>
        <span class="flex-1"></span>

        <button
          *ngIf="showFilterBtn"
          (click)="toggleFilter()"
          class="hidden sm:inline-flex ml-3"
          mat-stroked-button>
          <mat-icon [svgIcon]="filterButtonIcon"></mat-icon>
          <span class="ml-2">{{ filterButtonText }}</span>
        </button>
        <button
          (click)="onOpenSortColumnTable()"
          class="ml-4 flex-none"
          mat-icon-button
          matTooltip="Filter Columns"
          type="button">
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>

        <button
          class="ml-4 flex-none"
          color="primary"
          mat-stroked-button
          matTooltip="Add {{ title }}"
          type="button"
          (click)="onCreate()"
          *ngIf="showAddButton">
          <mat-icon svgIcon="mat:add"></mat-icon>Add
        </button>
        <!-- option add button -->
        <button
          class="hidden sm:inline-flex ml-3"
          mat-flat-button
          [color]="'primary'"
          [matMenuTriggerFor]="addOptionMenu"
          *ngIf="showAddOptionButton">
          <mat-icon class="icon-size-5" [svgIcon]="'mat:add'"></mat-icon>
          <span class="ml-2">
            {{ "Add New" }}
          </span>
        </button>
        <mat-menu #addOptionMenu>
          <ng-container>
            <button
              mat-menu-item
              *ngFor="let i of showAddOptionButtonValues"
              (click)="onAddOptionCreate(i)">
              {{ i.name }}
            </button>
          </ng-container>
        </mat-menu>
        <button
          *ngIf="showExportButton"
          (click)="onExport()"
          class="hidden sm:inline-flex ml-3"
          mat-stroked-button
          matTooltip="Export {{ title }}">
          <mat-icon class="icon-size-5" [svgIcon]="'mat:download'"></mat-icon>
          <span class="ml-2">Export</span>
        </button>
      </div>
      <div @fadeInUp class="bg-app-bar px-6 h-auto" *ngIf="showFilter">
        <div class="filter py-2 flex flex-row justify-end gap-4">
          <mat-form-field *ngIf="showSampleTypeFilter">
            <mat-label>Sample Type</mat-label>
            <mat-select
              placeholder="Filter by sample type"
              (selectionChange)="changeSampleType($event)">
              <mat-option
                *ngFor="let option of sampleTypeList"
                [value]="option"
                >{{ option.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showProgramFilter">
            <mat-label>Program</mat-label>
            <mat-select
              placeholder=" Filter by program"
              (selectionChange)="changeProgram($event)">
              <mat-option *ngFor="let option of programList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showStatusFilter">
            <mat-label>Sample Status</mat-label>
            <mat-select
              placeholder="Filter by Sample status"
              (selectionChange)="changeSampleStatus($event)">
              <mat-option *ngFor="let option of statusList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showPaymentStatusFilter">
            <mat-label>Payment Status</mat-label>
            <mat-select
              placeholder="Filter by Payment Status"
              (selectionChange)="changePaymentStatus($event)">
              <mat-option
                *ngFor="let option of paymentStatusList"
                [value]="option"
                >{{ option.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showPaymentMethodStatusFilter">
            <mat-label>Payment Method</mat-label>
            <mat-select
              placeholder="Filter by Payment Method"
              (selectionChange)="changePaymentMethod($event)">
              <mat-option
                *ngFor="let option of paymentMethodList"
                [value]="option"
                >{{ option.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <!-- 
          <mat-form-field *ngIf="showCompanyFilter">
            <mat-label>Company</mat-label>
            <mat-select
              placeholder="Filter by Company"
              (selectionChange)="changeCompany($event)">
              <mat-option *ngFor="let option of companyList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field> -->
          <vex-common-autocomplete
            *ngIf="showCompanyFilter"
            [valueList]="companyList"
            [showLabel]="false"
            [placeholder]="'Search by company'"
            (optionSelected)="changeCompany($event)">
          </vex-common-autocomplete>
          <mat-form-field *ngIf="showDateRange">
            <mat-date-range-input
              [formGroup]="datePickerForm"
              [rangePicker]="campaignOnePicker"
              [comparisonStart]="datePickerForm.value.start"
              [comparisonEnd]="datePickerForm.value.end">
              <input
                matStartDate
                placeholder="Start date"
                formControlName="start"
                appDateInputFormat
                maxlength="10" />
              <input
                matEndDate
                placeholder="End date"
                formControlName="end"
                appDateInputFormat
                maxlength="10" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="campaignOnePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>

      <table
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        matSort
        class="table-responsive overflow-x-auto full-wid scrollable-element">
        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container
            *ngIf="column.type === 'text'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ getDataByType(row, column) }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'file'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <button
                mat-icon-button
                [matTooltip]="'View File'"
                (click)="viewFileData(row[column.property])">
                <mat-icon svgIcon="mat:remove_red_eye"></mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type == 'productName'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div class="flex flex-row gap-2 justify-start items-center">
                <div>
                  {{ getDataByType(row, column) }}
                </div>

                <div
                  class="flex flex-row justify-start items-center gap-3 w-5"
                  *ngIf="!row.is_inventory">
                  <ng-container>
                    <mat-icon
                      svgIcon="heroicons_outline:gift"
                      style="color: #4f46e5"></mat-icon>
                  </ng-container>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'description'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              mat-cell
              [innerHTML]="getDataByType(row, column)"></td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'price'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div class="flex flex-row justify-between items-center">
                <span>$</span><span> {{ getDataByType(row, column) }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'badge'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="badge-style"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>

            <td
              *matCellDef="let row"
              class="text-center"
              [ngClass]="column.cssClasses"
              mat-cell>
              <div (click)="$event.stopPropagation()">
                <div
                  class="btn-group btn-group-sm badge-btn"
                  *ngIf="row[column.property]">
                  <span
                    class="badge badge-success"
                    [class]="getTextColor(row[column.property])">
                    {{ row[column.property] }}
                  </span>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- <ng-container
        *ngIf="column.type === 'id'"
        [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
          S.No &nbsp;&nbsp;
        </th>
        <td
          *matCellDef="let row; let i = index"
          [ngClass]="column.cssClasses"
          mat-cell>
          {{ paginator?.pageIndex * paginator?.pageSize + (i + 1) }}
        </td>
      </ng-container> -->

          <!-- ******** -->

          <ng-container
            *ngIf="column.type === 'toggle'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              mat-header-cell
              class="text-center uppercase"></th>

            <td
              *matCellDef="let row"
              class="w-4"
              [ngClass]="column.cssClasses"
              mat-cell>
              <button
                *ngIf="row.innerTableData?.data.length"
                mat-mini-fab
                class="toggle-btn"
                (click)="toggleInnerTable(row)">
                <mat-icon
                  class=""
                  [svgIcon]="
                    selectedItem?.id === row.id
                      ? 'mat:keyboard_arrow_up'
                      : 'mat:keyboard_arrow_down'
                  "></mat-icon>
              </button>
            </td>
          </ng-container>
          <!-- ******** -->

          <ng-container
            *ngIf="column.type === 'star'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <ng-container *ngFor="let item of [1, 2, 3, 4, 5]">
                <ng-container
                  *ngIf="item <= row[column.property]; else elseTemplate">
                  <span
                    class="material-icons text-warning"
                    style="color: #fe9a03"
                    >star</span
                  >
                </ng-container>
                <ng-template #elseTemplate>
                  <span class="material-icons text-warning">star_border</span>
                </ng-template>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            class="w-52"></th>
          <td
            *matCellDef="let row"
            class="flex items-center gap-1 text-secondary py-2"
            mat-cell>
            <ng-container *ngFor="let action of filterActionButtons(row)">
              <button
                *ngIf="action.key == 'viewFile' && row[action.property]"
                mat-flat-button
                [matTooltip]="'View File'"
                class="!text-white !bg-gray-400 min-w-36 buttonHover"
                (click)="
                  onViewFileData(
                    action?.label || row['name'],
                    row[action.property]
                  )
                ">
                <span>{{ action.label ? action.label : "View Form" }}</span>
                <!-- <mat-icon
                  iconPositionStart
                  matButtonIcon
                  svgIcon="mat:remove_red_eye"></mat-icon> -->
              </button>
              <button
                *ngIf="action.key == 'download' && row[action.property]"
                mat-flat-button
                [matTooltip]="'Download File'"
                class="!text-white !bg-gray-400 buttonHover"
                (click)="onDownload(row[action.property])">
                <span>Download</span>
                <mat-icon
                  iconPositionStart
                  matButtonIcon
                  svgIcon="mat:download"></mat-icon>
              </button>
              <button
                *ngIf="action.key == 'btn'"
                mat-flat-button
                [matTooltip]="action.label || ''"
                class="!text-white !bg-gray-400 min-w-36 buttonHover"
                (click)="onBtnAction(row)">
                <span>{{ action.label }}</span>
                <mat-icon
                  iconPositionStart
                  matButtonIcon
                  [svgIcon]="action.icon"></mat-icon>
              </button>
            </ng-container>
            <button
              (click)="$event.stopPropagation()"
              [matMenuTriggerData]="{ customer: row }"
              [matMenuTriggerFor]="actionsMenu"
              mat-icon-button
              type="button"
              *ngIf="
                filterBurgerActions(row) && filterBurgerActions(row).length > 0
              ">
              <mat-icon svgIcon="mat:more_horiz"></mat-icon>
            </button>
            <mat-menu
              #actionsMenu="matMenu"
              xPosition="before"
              yPosition="below">
              <ng-container *ngFor="let item of filterBurgerActions(row)">
                <!-- [disabled]="row.isApproved == 1 && item.label == 'Approve'" -->
                <button mat-menu-item (click)="onActionClick(item, row)">
                  <mat-icon [svgIcon]="item.icon"></mat-icon>
                  <span>{{ item.label }}</span>
                </button>
              </ng-container>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="visibleColumns.length">
            <div
              class="example-element-detail"
              *ngIf="element.innerTableData?.data.length">
              <div
                class="inner-table py-6 px-2"
                *ngIf="expandedElement && expandedElement?.id === element?.id">
                <table
                  #innerTables
                  mat-table
                  #innerSort="matSort"
                  [dataSource]="element.innerTableData"
                  multiTemplateDataRows
                  matSort>
                  <ng-container
                    *ngFor="
                      let column of innerColumns;
                      trackBy: trackByProperty
                    ">
                    <ng-container
                      *ngIf="column.type === 'text'"
                      [matColumnDef]="column.property">
                      <th
                        *matHeaderCellDef
                        class="uppercase"
                        mat-header-cell
                        mat-sort-header>
                        {{ column.label }}
                      </th>
                      <td class="p-4" *matCellDef="let row" mat-cell>
                        {{ getDataByType(row, column) }}
                      </td>
                    </ng-container>

                    <ng-container
                      *ngIf="column.type === 'price'"
                      [matColumnDef]="column.property">
                      <th
                        *matHeaderCellDef
                        class="uppercase"
                        mat-header-cell
                        mat-sort-header>
                        {{ column.label }}
                      </th>
                      <td
                        *matCellDef="let row"
                        [ngClass]="column.cssClasses"
                        mat-cell>
                        <div class="flex flex-row justify-between items-center">
                          <span>$</span
                          ><span> {{ getDataByType(row, column) }}</span>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container
                      *ngIf="column.type === 'file'"
                      [matColumnDef]="column.property">
                      <th
                        *matHeaderCellDef
                        class="uppercase"
                        mat-header-cell
                        mat-sort-header>
                        {{ column.label }}
                      </th>
                      <td
                        *matCellDef="let row"
                        [ngClass]="column.cssClasses"
                        mat-cell>
                        <button
                          mat-icon-button
                          [matTooltip]="'View File'"
                          (click)="viewFileData(row[column.property])">
                          <mat-icon svgIcon="mat:remove_red_eye"></mat-icon>
                        </button>
                      </td>
                    </ng-container>
                    <!-- ******* -->
                    <ng-container
                      *ngIf="column.type === 'status'"
                      [matColumnDef]="column.property">
                      <th
                        *matHeaderCellDef
                        class="uppercase"
                        mat-header-cell
                        mat-sort-header>
                        {{ column.label }}
                      </th>
                      <td
                        style="text-align: center"
                        *matCellDef="let row"
                        [ngClass]="column.cssClasses"
                        mat-cell>
                        <div class="lg:block">
                          <ng-container *ngIf="row.is_active">
                            <mat-icon
                              class="text-green-400 icon-size-5"
                              [svgIcon]="'heroicons_solid:check'"></mat-icon>
                          </ng-container>
                          <ng-container *ngIf="!row.is_active">
                            <mat-icon
                              class="text-gray-400 icon-size-5"
                              [svgIcon]="'heroicons_solid:x'"></mat-icon>
                          </ng-container>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container
                      *ngIf="column.type === 'image'"
                      [matColumnDef]="column.property">
                      <th
                        *matHeaderCellDef
                        class="uppercase"
                        mat-header-cell
                        mat-sort-header>
                        {{ column.label }}
                      </th>
                      <td *matCellDef="let row" class="w-8 min-w-8" mat-cell>
                        <div class="items-center flex p-2 justify-center">
                          <img
                            [src]="
                              row.image || 'assets/images/product inline.png'
                            "
                            class="avatar h-8 w-8 align-middle" />
                        </div>
                      </td>
                    </ng-container>

                    <ng-container
                      *ngIf="column.type === 'badge'"
                      [matColumnDef]="column.property">
                      <th
                        *matHeaderCellDef
                        class="badge-style"
                        mat-header-cell
                        mat-sort-header>
                        {{ column.label }}
                      </th>

                      <td
                        *matCellDef="let row"
                        class="text-center"
                        [ngClass]="column.cssClasses"
                        mat-cell>
                        <div (click)="$event.stopPropagation()">
                          <div
                            class="btn-group btn-group-sm badge-btn"
                            *ngIf="row[column.property]">
                            <span
                              class="badge badge-success"
                              [class]="getTextColor(row[column.property])">
                              {{ row[column.property] }}
                            </span>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                  </ng-container>
                  <!-- Action Column -->
                  <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
                    <td
                      *matCellDef="let row"
                      class="w-10 text-secondary"
                      mat-cell>
                      <button
                        (click)="$event.stopPropagation()"
                        [matMenuTriggerData]="{ customer: row }"
                        [matMenuTriggerFor]="actionsMenu"
                        mat-icon-button
                        type="button">
                        <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                      </button>
                      <mat-menu
                        #actionsMenu="matMenu"
                        xPosition="before"
                        yPosition="below">
                        <ng-container
                          *ngFor="let item of filterBurgerInnerActions(row)">
                          <!-- [disabled]="row.isApproved == 1 && item.label == 'Approve'" -->
                          <button
                            mat-menu-item
                            (click)="onActionClick(item, row)">
                            <mat-icon [svgIcon]="item.icon"></mat-icon>
                            <span>{{ item.label }}</span>
                          </button>
                        </ng-container>
                      </mat-menu>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="innerColumn"
                    [class.example-element-row]="innerColumn.length"
                    [class.example-expanded-row]="
                      expandedElement === element
                    "></tr>
                  <tr mat-row *matRowDef="let row; columns: innerColumn"></tr>
                </table>
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="visibleColumns"
          class="hover:bg-hover trans-ease-out"></tr>

        <tr
          mat-row
          *matRowDef="let element; columns: visibleColumns"
          [class.example-element-row]="element.innerTableData?.data.length"
          [class.example-expanded-row]="expandedElement === element"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="example-detail-row"></tr>

        <!-- <tr
          [hidden]="
            queryResults && queryResults.items && queryResults.items.length > 0
          "
          mat-footer-row
          *matFooterRowDef="['noRecord']"></tr> -->

        <ng-container matColumnDef="noRecord">
          <td
            mat-footer-cell
            *matFooterCellDef
            class="text-center"
            [attr.colspan]="columns.length">
            No records found.
          </td>
        </ng-container>

        <tr
          [hidden]="
            (queryResults &&
              queryResults.items &&
              queryResults.items.length > 0) ||
            isLoading
          "
          mat-footer-row
          *matFooterRowDef="['noRecord']"></tr>
        <tr
          [hidden]="!isLoading"
          mat-footer-row
          class="p-0"
          *matFooterRowDef="['spinner']"></tr>

        <ng-container matColumnDef="spinner">
          <td
            mat-footer-cell
            *matFooterCellDef
            class="text-center p-0"
            [attr.colspan]="columns.length">
            <div id="loader" class="text-center py-6">
              <div class="loader mx-auto mb-4">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p class="text-gray-700 text-base">One moment please...</p>
            </div>
          </td>
        </ng-container>
      </table>

      <div class="pagination" *ngIf="showPagination">
        <mat-paginator
          #paginator
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize"
          [length]="resultsLength"
          class="sticky left-0">
        </mat-paginator>
      </div>
    </div>
  </vex-page-layout-content>
</vex-page-layout>
