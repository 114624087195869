<!-- <app-common-table
  #cnTable
  [title]="'Branding'"
  [crumbsMenu]="'Program'"
  [actions]="actions"
  [burgerActions]="burgerActions"
  [columns]="columns"
  [showAddButton]="false"
  (onLoadItemsEvent)="onLoadItemsEvent($event)"
  (onActionClickEmit)="onActionClickEmit($event)"
  (onAddClick)="onAddClick()"
  [isLoading]="isLoading">
</app-common-table> -->
<vex-multiple-row-common-table
  #table
  [title]="'Branding'"
  [columns]="columns"
  [innerColumns]="innerColumns"
  [isLoading]="isLoading"
  (onLoadItemsEvent)="onLoadItemsEvent($event)"
  (onActionClickEmit)="onActionClickEmit($event)"
  [burgerActions]="burgerActions"
  [burgerInnerActions]="burgerInnerActions"
  [showAddButton]="false"
  [showBrandToggleList]="true"
  (onBrandToggleList)="onBrandToggleList($event)">
</vex-multiple-row-common-table>

