import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { LoaderComponent } from 'src/app/layouts/components/loader/loader.component';

@Component({
  selector: 'vex-preview-brand-certificate',
  templateUrl: './preview-brand-certificate.component.html',
  styleUrls: ['./preview-brand-certificate.component.scss'],
  standalone: true,
  imports: [SharedModule, LoaderComponent]
})
export class PreviewBrandCertificateComponent {
  isLoading: boolean = false;
  brandData: any;
  constructor(
    private dialogRef: MatDialogRef<PreviewBrandCertificateComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sanitizer: DomSanitizer
  ) {
    this.brandData = data.data;
    this.isLoading = data.isLoading;
  }
  async onSubmit() {
    this.dialogRef.close({ submit: true });
  }
}
