<app-loader [loading]="isLoading"></app-loader>
<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    {{ "Brand Certificate" }}
  </h2>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="flex flex-col flex-auto mt-2 gap-4">
  <div id="certificate" class="p-4 shadow-lg">
    <div class="border-solid border-[#2c5f9c] border-[3px]">
      <div class="border-solid border-[#2c5f9c] border-4 m-0.5 p-0.5">
        <div class="px-1 py-6 border-solid border-[#2c5f9c] border-[3px]">
          <table>
            <tr>
              <td style="width: 45%">
                <img [src]="brandData.programImg" class="logo1" alt="" />
              </td>

              <td>
                <h2 class="text-3xl font-bold title">
                  International Fluids Consortium
                </h2>
              </td>
            </tr>
          </table>
          <div class="inner-content px-11">
            <h1 class="text-center text-5xl big-title mt-2">
              Certificate of Brand Registration
            </h1>
            <h4 class="text-center my-4">Issued to</h4>
            <h2 class="text-3xl font-bold mb-4 text-center">
              {{ brandData.companyName }}
            </h2>
            <p class="mx-auto text-base m-0 leading-5">
              This document certifies that the licensed company named above is
              authorized to use the International Fluids Consortium (IFC)
              Certification Mark in association with a registered fluid product,
              as specified below:
            </p>
            <table class="mx-auto my-2 w-2/3">
              <tr>
                <td class="text-base text-black">Product Brand Name:</td>
                <td class="font-semibold text-base">
                  {{ brandData.brandName }}
                </td>
              </tr>
              <tr>
                <td class="text-base text-black">SAE Viscosity Grade:</td>
                <td class="font-semibold text-base">{{ brandData.grade }}</td>
              </tr>
              <tr>
                <td class="text-base text-black">IFC Specification:</td>
                <td class="font-semibold text-base">
                  {{ brandData.specification }}
                </td>
              </tr>
              <tr>
                <td class="text-base text-black">
                  Brand Name Registration ID:
                </td>
                <td class="font-semibold text-base">
                  {{ brandData.registrationId }}
                </td>
              </tr>
              <tr>
                <td class="text-base text-black">Expiration Date:</td>
                <td class="font-semibold text-base">
                  {{ brandData.expiryDate }}
                </td>
              </tr>
            </table>
            <p class="mx-auto text-base m-0 leading-5">
              This fluid has been tested and meets the rigorous quality
              requirements for the IFC Specification above. The licensed company
              agrees to comply with the quality standards set forth in the IFC
              Licensing and Fluid Certification Program. The Brand Name
              Registration ID shown above must appear on all container labels of
              the certified product.
            </p>
            <p class="mx-auto text-base mt-4">
              For questions regarding this Certificate of Registration, please
              contact the Center for Quality Assurance.
            </p>
            <div class="flex justify-between items-center">
              <div>
                <img
                  src="../../../../../../../../assets/img/logo/CQA-1a_v2_no_text 4x.png"
                  class="h-9 w-fit my-3"
                  alt="" />
                <p class="mb-0 leading-5">
                  Center for Quality Assurance <br />
                  4800 James Savage Road <br />Midland, MI 48642 USA
                </p>
                <div class="flex gap-2 leading-5">
                  <p class="mb-0">E-mail :</p>
                  <p class="mb-0">{{ brandData.programEmail }}</p>
                </div>
                <div class="flex gap-2 leading-5">
                  <p class="mb-0">Website:</p>
                  <p class="mb-0">{{ brandData.programContentURL }}</p>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-4">
                <div class="flex">
                  <label for="" class="w-20">Name:</label>
                  <input
                    class="font-bold border-b border-black px-2 w-72"
                    type="text"
                    readonly
                    value="Rebecca Cox" />
                </div>
                <div class="flex">
                  <label for="" class="w-20">Title:</label>
                  <input
                    class="font-bold border-b border-black px-2 w-72"
                    type="text"
                    readonly
                    value="President" />
                </div>
                <div class="flex">
                  <label for="" class="w-20">Signature:</label>
                  <div
                    class="border-b border-black px-2"
                    style="display: inline-block; width: 288px">
                    <img
                      src="https://s3.us-west-2.amazonaws.com/cdn.fluidquality.com/dev/0Xk64DhcHE.jpg"
                      alt="signRebeccaCox"
                      width="140"
                      height="30" />
                  </div>
                </div>
                <div class="flex">
                  <label for="" class="w-20">Date:</label>
                  <input
                    class="font-bold border-b border-black px-2 w-72"
                    type="text"
                    readonly
                    [value]="brandData.createdDate" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <p class="font-semibold text-sm m-0 p-2">
        Copyright © 2024 Center for Quality Assurance
      </p>
      <p class="font-semibold text-sm m-0 p-2">
        Form IFC08 Product Brand Name Registration Certificate Rev. 20231214
      </p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">Cancel</button>
  <!-- <button color="primary" mat-flat-button type="submit" (click)="onSubmit()">
    Generate
  </button> -->
</mat-dialog-actions>
