import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { environment } from 'src/assets/environments/environment.prod';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class CartService {
  private refreshList = new BehaviorSubject<boolean>(false);
  cartItemCount: number = 0;
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}
  getListObservable() {
    return this.refreshList.asObservable();
  }

  triggerListRefresh() {
    this.refreshList.next(true);
  }

  getCart(id: any, programConfigId: any, quotId?: string): Observable<any> {
    let url: string;

    if (quotId) {
      url = `cart/${id}/${programConfigId}?quotationId=${quotId}`;
    } else {
      url = `cart/${id}/${programConfigId}`;
    }

    return this.http.get(url, httpOptions).pipe(
      map((resp: any) => {
        let retData: any;
        if (resp && resp.data) {
          let rows = resp.data;
          rows.cartItems.forEach((i: any) => {
            if (Array.isArray(i.programConfig.attachment)) {
              let imageUrl =
                environment.mediaUrl +
                i.programConfig.attachment[0]?.dir +
                '/' +
                i.programConfig.attachment[0]?.fileName;
              i.imageUrl = imageUrl;
            }
          });
          let temp: any = {
            ...rows,
            id: rows.id
          };
          this.cartItemCount = temp?.cartItems?.length;
          retData = temp;
        }
        return retData;
      })
    );
  }

  createCart(data: any): Observable<any> {
    return this.http.post('cart', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  updateCartItem(id: any, data: any): Observable<any> {
    return this.http.put('cart/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  deleteCartItem(cartId: any, cartItemId: any): Observable<any> {
    return this.http
      .delete(`cart/${cartId}/cartItem/` + cartItemId, httpOptions)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }

  deleteCart(cartID: any, programConfigID: any): Observable<any> {
    return this.http
      .delete(`cart/clearCart/${cartID}/${programConfigID}`, httpOptions)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }

  createSample(data: any): Observable<any> {
    let datas: any = {
      cartData: { ...data }
    };
    return this.http.post('checkout/sampleFlow', datas, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  createBrand(data: any): Observable<any> {
    return this.http.post('brand/multiple', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
}
