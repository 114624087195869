import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { firstValueFrom, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { TermsServiceComponent } from 'src/app/pages/auth/terms-service/terms-service.component';
import { CMSService } from 'src/app/pages/master-data/articles/cms.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, AsyncPipe, RouterLink]
})
export class FooterComponent implements OnInit, OnDestroy {
  sidenavCollapsed$: Observable<boolean> = this.layoutService.sidenavCollapsed$;
  sidenavOpen$: Observable<boolean> = this.layoutService.sidenavOpen$;
  cmsData: any;
  constructor(
    private readonly layoutService: VexLayoutService,
    private matDialog: MatDialog,
    private cmsService: CMSService
  ) {}

  ngOnInit() {
    this.getCms();
  }

  async getCms() {
    let resp = await firstValueFrom(this.cmsService.getAllPages());
    this.cmsData = resp.items;
  }
  ngOnDestroy(): void {}
  openCMS(name: any) {
    this.getCms();
    const data = this.cmsData.filter((i: any) => {
      return i.slug == name;
    });
    this.matDialog.open(TermsServiceComponent, {
      data: { data: data },
      autoFocus: false,
      width: '60%'
    });
  }
}
