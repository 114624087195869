<div class="flex flex-row gap-2 items-center">
  <ng-container *ngFor="let item of formData">
    <mat-form-field class="w-56">
      <mat-label>{{ item.label }}</mat-label>
      <mat-date-range-input
        [formGroup]="item.formGroup"
        [rangePicker]="campaignOnePicker"
        [comparisonStart]="item.formGroup.value.start"
        [comparisonEnd]="item.formGroup.value.end">
        <input
          matStartDate
          placeholder="Start date"
          formControlName="start"
          appDateInputFormat
          maxlength="10" />
        <input
          matEndDate
          placeholder="End date"
          formControlName="end"
          appDateInputFormat
          maxlength="10" />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matSuffix
        [for]="campaignOnePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
    </mat-form-field>
  </ng-container>
</div>
