import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDateInputFormat]',
  exportAs: 'appDateInputFormat',
  standalone: true
})
export class DateInputFormatDirective {
  dateFormat: string;

  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    let dateFormatValue = input.value;
    dateFormatValue = this.formatDate(dateFormatValue);
    if (dateFormatValue.length > 10) {
      dateFormatValue = dateFormatValue.slice(0, 10);
    }
    input.value = dateFormatValue;
  }

  private formatDate(value: string): string {
    this.dateFormat = value;
    const b = parseInt(this.dateFormat);
    const c = parseInt(this.dateFormat.slice(-1));
    const d = parseInt(this.dateFormat.slice(-2));
    if (this.dateFormat.length === 0) {
      this.dateFormat = '';
    } else if (this.dateFormat.length > 0) {
      this.dateFormat = this.dateFormat
        .replace(/^([0-9][0-9])([0-9])$/g, '$1-$2')
        .replace(/^([0-9][0-9])-([0-9][0-9])([0-9]+)$/g, '$1-$2-$3')
        .replace(/[^0-9-]/g, '');
    }
    if (this.dateFormat.length === 1 && b >= 2 && b <= 9) {
      this.dateFormat = `0${this.dateFormat}`;
    } else if (this.dateFormat.length === 2 && b > 12) {
      this.dateFormat = '01';
    } else if (this.dateFormat.length === 4 && c >= 4 && c <= 9) {
      this.dateFormat = this.dateFormat.slice(0, -1) + `0${c}`;
    } else if (this.dateFormat.length === 5 && d > 31) {
      this.dateFormat =
        this.dateFormat.substring(0, this.dateFormat.length - 2) + '01';
    }
    return this.dateFormat;
  }
}
