<div class="px-5 h-full flex-col justify-between bg-[#f4f6f8] overflow-auto">
  <div>
    <div class="flex justify-between items-center mt-3 mb-7">
      <div class="flex gap-1">
        <!-- <mat-icon
          class="text-primary-600"
          svgIcon="mat:shopping_cart"></mat-icon> -->
        <h2 class="font-bold text-lg">Shopping Cart</h2>
      </div>
      <p
        *ngIf="
          cartData &&
          cartData?.cartItems &&
          cartData?.cartItems.length > 0 &&
          !licenseAgreementCart
        "
        class="font-semibold cursor-pointer text-red-400"
        (click)="onClearCart()">
        Remove all
      </p>
      <p
        *ngIf="
          cartData && cartData?.cartItems && cartData?.cartItems.length == 0
        ">
        <button mat-icon-button type="button" (click)="cancel()">
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </p>
    </div>

    <div
      class="cart-cards"
      *ngIf="cartData && cartData?.cartItems && cartData?.cartItems.length > 0">
      <ng-container>
        <div
          *ngFor="let item of cartData?.cartItems"
          class="sm:flex block mt-3 justify-between items-center gap-4">
          <div class="flex items-center gap-3">
            <!-- <img
              class="h-18 w-50 object-cover rounded"
              [src]="item.img"
              alt="" /> -->
            <div class="w-full">
              <ng-container *ngIf="item.class == 'sample'">
                <h4 class="font-medium text-lg">
                  {{ item?.sampleType?.name }}
                </h4>
              </ng-container>
              <ng-container *ngIf="item.class == 'brand'">
                <h4 class="font-medium text-lg">{{ "Brands" }}</h4>
              </ng-container>

              <ng-container *ngIf="item.class == 'LicenseAgreement'">
                <h4 class="font-medium text-lg">{{ item?.name }}</h4>
              </ng-container>
            </div>
          </div>
          <div
            class="flex gap-4 sm:mt-0 mt-3 justify-between items-center"
            *ngIf="item.class == 'sample'">
            <!-- <div class="flex gap-2">
              <h5 class="font-medium">1</h5>
            </div> -->
            <div class="price flex flex-col justify-end items-end gap-2">
              <mat-icon
                class="cursor-pointer text-red-400"
                (click)="deleteCart(item)"
                svgIcon="mat:delete"></mat-icon>
              <h6 class="font-bold">
                {{ item.price | currency: "USD" : "symbol" : "1.2-2" }}
              </h6>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="min-h-screen flex flex-col justify-center items-center overflow-hidden"
      *ngIf="cartData?.cartItems.length == 0 || cartData == null">
      <div>
        <div class="flex flex-col justify-center items-center gap-4">
          <div class="font-bold">Your cart is empty</div>
          <button
            color="primary"
            class="rounded-sm"
            (click)="cancel()"
            mat-flat-button>
            Click to continue add program
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="my-5"
    *ngIf="cartData && cartData?.cartItems && cartData?.cartItems.length > 0">
    <div class="sm:px-4 px-2">
      <mat-divider></mat-divider>
      <table class="w-full mt-4">
        <!-- <tr>
          <td class="text-left">Subtotal</td>
          <td class="text-right font-medium">
            {{ cartData?.totalPrice | currency: "USD" : "symbol" : "1.2-2" }}
          </td>
        </tr> -->
        <!-- <tr>
          <td class="text-left">Processing Fees</td>
          <td class="text-right font-medium">
            {{ cartData?.processingFee | currency: "USD" : "symbol" : "1.2-2" }}
          </td>
        </tr> -->
        <!-- <tr>
          <td class="text-left">Taxes</td>
          <td class="text-right font-medium">
            {{ cartData?.tax | currency: "USD" : "symbol" : "1.2-2" }}
          </td>
        </tr> -->
        <tr>
          <td class="font-semibold text-left">Total Outstanding:</td>
          <td class="font-bold text-right text-[#17aeff]">
            {{ cartData?.totalPrice | currency: "USD" : "symbol" : "1.2-2" }}
          </td>
        </tr>
      </table>
    </div>
    <div class="sm:flex gap-2 block justify-center mt-3 py-4">
      <button class="rounded-sm cancel" (click)="cancel()" mat-stroked-button>
        Cancel
      </button>
      <button
        color="primary"
        class="rounded-sm"
        (click)="checkout()"
        mat-flat-button>
        Checkout
      </button>
    </div>
  </div>
</div>
