import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { Observable, of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CMSService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}

  getPages(queryParams: any) {
    let params = this.appSettingsService.queryStringFormat(queryParams);
    return this.http.get('cms?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = { items: [], totalCount: 0, errorMessage: '' };
        if (resp.data && Array.isArray(resp.data.rows)) {
          for (let item of resp.data.rows) {
            let temp: any = {
              ...item,
              id: item.id,
              name: item.name,
              status: item.is_active === 0 ? 'Inactive' : 'Active',
              createdAt: moment(item.createdAt).format('DD-MMM-YYYY')
            };
            retData.items.push(temp);
          }
          retData.totalCount = resp.data.count;
        } else {
          retData.errorMessage = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }
  private cachedData: any = null;
  getAllPages() {
    // Return cached data if available
    if (this.cachedData) {
      return of(this.cachedData);
    }
    return this.http.get('cms', httpOptions).pipe(
      map((resp: any) => {
        let retData: any = { items: [], totalCount: 0, errorMessage: '' };
        if (resp.data && Array.isArray(resp.data.rows)) {
          for (let item of resp.data.rows) {
            let temp: any = {
              ...item,
              id: item.id,
              name: item.name,
              status: item.is_active === 0 ? 'Inactive' : 'Active',
              createdAt: moment(item.createdAt).format('DD-MMM-YYYY')
            };
            retData.items.push(temp);
          }
          // Cache the data
          this.cachedData = retData;
          retData.totalCount = resp.data.count;
        } else {
          retData.errorMessage = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }

  createCMS(data: any): Observable<any> {
    return this.http.post('cms', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  updateCMS(id: any, data: any): Observable<any> {
    return this.http.put('cms/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
  getPageById(pageId: any) {
    return this.http.get('cms/' + pageId, httpOptions).pipe(
      map((resp: any) => {
        return parseResponse(resp);
      })
    );
  }

  deletePage(pageId: any) {
    return this.http.delete('cms/' + pageId, httpOptions).pipe(
      map((resp: any) => {
        return parseResponse(resp);
      })
    );
  }
  //handle error messages
  handleError(err: any) {
    this.appSettingsService.handleError(err);
  }
}
