import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { FormValidationService } from 'src/app/service/form-validation/form-validation.service';
import { ToastrService } from 'ngx-toastr';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { stagger40ms } from '@vex/animations/stagger.animation';
import Swal from 'sweetalert2';
import { firstValueFrom } from 'rxjs';
import { ProgramBrandingService } from '../../program-branding.service';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { LoaderComponent } from 'src/app/layouts/components/loader/loader.component';
@Component({
  selector: 'vex-reject-brand',
  standalone: true,
  imports: [SharedModule, LoaderComponent],
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms, stagger40ms],
  templateUrl: './reject-brand.component.html',
  styleUrls: ['./reject-brand.component.scss']
})
export class RejectBrandComponent {
  form: FormGroup;
  brandData: any[] = [];
  BrandRequestId: any;
  isLoading: boolean = false;
  notesCtrl = new FormControl('', Validators.required);
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private formValidationService: FormValidationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RejectBrandComponent>,
    private programBrandingService: ProgramBrandingService,
    private appSettingsService: AppSettingsService
  ) {
    // Initialize the form group with a form array
    this.form = this.fb.group({
      brands: this.fb.array([])
    });
    this.brandData = data.brandData;
    this.BrandRequestId = data.BrandRequestId;
    this.addBrands();
  }

  // Method to create form controls for each brand
  get brands(): FormArray {
    return this.form.get('brands') as FormArray;
  }

  addBrands(): void {
    let brandForm = this.fb.group({
      brandName: [],
      notes: [],
      brandId: []
    });
    this.brandData.forEach((brand) => {
      brandForm.patchValue({
        brandName: brand.brandName,
        brandId: brand.brandId
      });
      this.brands.push(brandForm);
    });
  }
  getBrandGroupNameControl(control: string, index: number): FormControl {
    const brandGroup = this.brands.at(index) as FormGroup;
    return brandGroup
      ? (brandGroup.get(control) as FormControl)
      : new FormControl(null);
  }
  onSubmit() {
    Swal.fire({
      title: 'Brand Reject',
      text: `Are you sure you want to Reject All Brand?`,
      icon: 'info',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        let data: any = {
          brandData: this.brandData.map((i: any, index: number) => {
            console.log(this.form.value);
            let notes = this.notesCtrl.value;
            return {
              brandId: parseInt(i.brandId),
              sampleId: parseInt(i.sampleId),
              brandNameRegistrationId: '',
              BrandNote: notes
            };
          }),
          BrandStatus: 'Rejected',
          BrandApprove: 'No',
          BrandRequestId: this.BrandRequestId
        };
        try {
          let resp = await firstValueFrom(
            this.programBrandingService.onUpdateBrand(data)
          );
          this.toastr.success('Brands rejected Successfully!');
          this.isLoading = false;
          this.dialogRef.close({ submit: true });
        } catch (err) {
          this.appSettingsService.handleError(err);
          this.isLoading = false;
        }
      }
    });
  }
}
