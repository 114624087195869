import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/core/shared/shared.module';
import {
  CommonTableComponent,
  QueryParamsModel,
  TableColumn
} from 'src/app/layouts/components/common-table/common-table.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { LicenseeAgreementService } from '../program-licensee-agrement-list/licensee-agreement.service';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router
} from '@angular/router';
import { FinancialService } from '../program-finacials/financial.service';
import { ViewPdfComponent } from 'src/app/pages/master-data/program-config/components/view-pdf/view-pdf.component';
import { ProgramBrandingService } from './program-branding.service';
import { UserAuthenticationService } from 'src/app/core/navigation/user-authantication.service';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';
import { ProgramIfcService } from '../../service/programIfc.service';
import { environment } from 'src/assets/environments/environment.prod';
import { firstValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { ListRefreshService } from 'src/app/service/list-refresh/list-refresh.service';
import Swal from 'sweetalert2';
import { MultipleRowCommonTableComponent } from 'src/app/layouts/components/multiple-row-common-table/multiple-row-common-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { RejectBrandComponent } from './component/reject-brand/reject-brand.component';
import { PreviewBrandCertificateComponent } from './component/preview-brand-certificate/preview-brand-certificate.component';
import moment from 'moment';
import { ViewImageComponent } from 'src/app/core/shared/view-image/view-image.component';
import { ListConfigurationService } from 'src/app/pages/list-configuration/list-configuration.service';
@Component({
  selector: 'vex-program-branding',
  standalone: true,
  imports: [
    SharedModule,
    CommonTableComponent,
    MultipleRowCommonTableComponent
  ],
  templateUrl: './program-branding.component.html',
  styleUrls: ['./program-branding.component.scss']
})
export class ProgramBrandingComponent {
  @ViewChild('cnTable', { static: true })
  commonTableRef!: CommonTableComponent;
  public actions: Array<any> = [];
  public burgerActions: Array<any> = [];

  public multiTableColumns: TableColumn<any>[] = [
    { label: 'ID', property: 'id', type: 'text', visible: false },
    {
      label: 'Toggle',
      property: 'sample',
      type: 'toggle',
      visible: true,
      remove: true
    },
    {
      label: 'Company',
      property: 'CompanyName',
      type: 'text',
      visible: true
    },
    {
      label: 'Date Submitted',
      property: 'dateSubmitted',
      type: 'text',
      visible: true
    },

    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true
    }
  ];

  // innerColumns: TableColumn<any>[] = [
  //   { label: 'Name', property: 'brandName', type: 'text', visible: true },
  //   {
  //     label: 'Linked Company Name',
  //     property: 'LinkCompanyName',
  //     type: 'text',
  //     visible: true
  //   },

  //   {
  //     label: 'Formulation Code',
  //     property: 'formulationApprovalCode',
  //     type: 'text',
  //     visible: true
  //   },
  //   {
  //     label: 'Brand Registration Id',
  //     property: 'brandNameRegistrationId',
  //     type: 'text',
  //     visible: true
  //   },
  //   {
  //     label: 'Brand Registration Expiration Date',
  //     property: 'Form08ExpDate',
  //     type: 'text',
  //     visible: true
  //   },
  //   {
  //     label: 'Status',
  //     property: 'BrandStatus',
  //     type: 'text',
  //     visible: true
  //   },
  //   {
  //     label: 'Actions',
  //     property: 'actions',
  //     type: 'button',
  //     visible: true
  //   }
  // ];

  innerColumns: TableColumn<any>[] = [];
  burgerInnerActions = [
    { key: 'viewInnerFile', icon: 'mat:remove_red_eye', label: 'View' },
    { key: 'downloadInnerFile', icon: 'mat:download', label: 'Download' },
    { key: 'viewInnerMark', icon: 'mat:remove_red_eye', label: 'View QR Code' },
    {
      key: 'downloadInnerMark',
      icon: 'mat:download',
      label: 'Download QR Code'
    }
  ];
  @ViewChild('table', { static: false })
  multipleRowCommonTable!: MultipleRowCommonTableComponent;
  userRole: string | null;
  userInfo: any;
  filter: any = {};
  @Input() programConfigId: any;
  currentProgram: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isLoading = true;
  multiRowTableData: any;
  tableData: any;
  private subscription: Subscription;
  private isComponentInitialized: boolean = false;
  toggleSwitchStr = 'brands';
  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService,
    public appSettingsService: AppSettingsService,
    private programBrandingService: ProgramBrandingService,
    public userCheck: UserAuthenticationService,
    private dataService: DataService,
    private _router: Router,
    private programIfcService: ProgramIfcService,
    private listRefreshService: ListRefreshService,
    private listConfigurationService: ListConfigurationService,
    private route: ActivatedRoute
  ) {}
  type: any = null;
  async ngOnInit() {
    const CQAColumn: any = [
      { label: 'ID', property: 'id', type: 'text', visible: false },
      {
        label: 'Company Name',
        property: 'CompanyName',
        type: 'text',
        visible: true
      },
      {
        label: 'Company Number',
        property: 'CompanyNumber',
        type: 'text',
        visible: true
      },
      {
        label: 'License Type',
        property: 'LicType',
        type: 'text',
        visible: true
      },
      {
        label: 'Brand Approval Code',
        property: 'BrandApprovalCode',
        type: 'text',
        visible: true
      },
      {
        label: 'Licensed Brand Names',
        property: 'LicensedBrandNames',
        type: 'text',
        visible: true
      },
      {
        label: 'Specification',
        property: 'Specification',
        type: 'text',
        visible: true
      },
      {
        label: 'Viscosity',
        property: 'Viscosity',
        type: 'text',
        visible: true
      },
      {
        label: 'labels approved',
        property: 'Labelsapproved',
        type: 'text',
        visible: true
      },

      {
        label: 'Actions',
        property: 'actions',
        type: 'button',
        visible: true
      }
    ];
    const companyColumn: any = [
      { label: 'ID', property: 'id', type: 'text', visible: false },
      {
        label: 'Company Name',
        property: 'CompanyName',
        type: 'text',
        visible: true
      },
      {
        label: 'Company Number',
        property: 'CompanyNumber',
        type: 'text',
        visible: true
      },
      {
        label: 'License Type',
        property: 'LicType',
        type: 'text',
        visible: true
      },
      {
        label: 'Product Name',
        property: 'ProductName',
        type: 'text',
        visible: true
      },
      {
        label: 'Specification',
        property: 'Specification',
        type: 'text',
        visible: true
      },
      {
        label: 'Viscosity',
        property: 'Viscosity',
        type: 'text',
        visible: true
      },
      {
        label: 'Brand Approval Code',
        property: 'BrandApprovalCode',
        type: 'text',
        visible: true
      },
      {
        label: 'Licensed Brand Names',
        property: 'LicensedBrandNames',
        type: 'text',
        visible: true
      },
      {
        label: 'labels approved',
        property: 'Labelsapproved',
        type: 'text',
        visible: true
      },

      {
        label: 'Actions',
        property: 'actions',
        type: 'button',
        visible: true
      }
    ];
    if (this.userCheck.checkUser()) {
      this.innerColumns.push(...CQAColumn);
    } else {
      this.innerColumns.push(...companyColumn);
    }
    this.isComponentInitialized = true;
    this.type = this.route.snapshot.queryParams['type'];
    await this.getListViewColumns('brand');
    this.burgerActions = [
      { key: 'view', icon: 'mat:description', label: 'View Form' }
      // { key: 'delete', icon: 'mat:delete_forever', label: 'Delete' }
    ];

    this.userRole = this.appSettingsService.getUserRole();
    this.userInfo = this.appSettingsService.userData().userDetails;
    this.filter.where = {
      programConfigId: this.programConfigId
    };
    if (this.userCheck.checkUser()) {
      this.burgerActions.push(
        {
          key: 'approveBrand',
          icon: 'mat:edit',
          label: 'Approve'
        },
        {
          key: 'rejectAllBrand',
          icon: 'mat:edit',
          label: 'Reject All Brand'
        }
      );
      this.burgerInnerActions.push(
        { key: 'brandUnpublish', icon: 'mat:publish', label: 'Unpublish' },
        { key: 'brandPublish', icon: 'mat:publish', label: 'Publish' }
      );

      const actionsIndex = this.multiTableColumns.findIndex(
        (column) => column.property === 'actions'
      );

      const newColumns: TableColumn<any>[] = [
        {
          label: 'Company',
          property: 'CompanyName',
          type: 'text',
          visible: true
        }
      ];

      // Insert new columns before the "Actions" column
      // this.columns.splice(actionsIndex, 0, ...newColumns);
    }
    setTimeout(() => {
      this.multipleRowCommonTable.initLoadItems();
    }, 1000);
    //subscribe program value selected
    this.subscription = this.dataService.program$.subscribe((res: any) => {
      if (res) {
        this.programConfigId = res?.id;
        if (!this.isComponentInitialized) {
          // Only call if the component is not initializing
          setTimeout(() => {
            this.multipleRowCommonTable.initLoadItems();
          }, 1000);
        }

        // After initial setup, set the flag to false
        this.isComponentInitialized = false;
      }
    });

    this.dataService.getProgramCartObservable().subscribe((res) => {
      if (res) {
        this.init();
        if (!this.isComponentInitialized) {
          // Only call if the component is not initializing
          setTimeout(() => {
            this.multipleRowCommonTable.initLoadItems();
          }, 1000);
        }

        // After initial setup, set the flag to false
        this.isComponentInitialized = false;
      }
    });
    this.listRefreshService
      .getListObservable()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res) {
          if (!this.isComponentInitialized) {
            // Only call if the component is not initializing
            setTimeout(() => {
              this.multipleRowCommonTable.initLoadItems();
            }, 1000);
          }

          // After initial setup, set the flag to false
          this.isComponentInitialized = false;
        }
      });

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.type = null;
        this.type = this.route.snapshot.queryParams['type'];
        this.multipleRowCommonTable.initLoadItems();
      }
    });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async init() {
    try {
      this.currentProgram = await firstValueFrom(
        this.dataService.getUserData()
      );
      if (this.currentProgram) {
        this.programConfigId = this.currentProgram?.id;
      }
      this.inItFilterOptions();
    } catch {}
  }

  inItFilterOptions() {
    let url = this._router.url;
    const extractedString = url.split('/')[1].split('?')[0];
    this.filter.where = {
      companyId: !this.userCheck.checkUser()
        ? this.userInfo?.company?.id
        : null,
      programConfigId: this.programConfigId,
      uploadToWebsite: extractedString == 'WebsiteBrandList' ? 'true' : null
    };
  }
  /**
   * onLoadItemsEvent
   */
  public async onLoadItemsEvent(queryParams: QueryParamsModel) {
    this.isLoading = true;

    await this.init();

    const retData: any = {
      items: [],
      totalCount: 0,
      error: ''
    };
    // this.multipleRowCommonTable.loadItems(retData);

    if (Object.keys(this.filter).length) {
      queryParams.filter = JSON.stringify(this.filter);
    }
    this.programBrandingService
      .getBrandingList(queryParams)
      .subscribe((resp) => {
        // this.tableData = null;
        // this.multiRowTableData = null;
        this.tableData = resp;

        const groupedData: {
          [key: string]: { companyDetails: any; brandData: any[] };
        } = {};

        resp.items.forEach((brand: any, index: number) => {
          const brandRequestId = brand.BrandRequestId;

          if (!groupedData[brandRequestId]) {
            groupedData[brandRequestId] = {
              dateSubmitted: brand.dateSubmitted,
              ...brand.companyDetails,
              companyId: +brand.companyDetails.id,
              id: index,
              brandData: [],
              BrandRequestId: brand.BrandRequestId,
              file: {
                fileUrl: brand.fileUrl,
                originalFileName: brand.originalFileName
              },
              sampleID: brand.sampleID,
              brandBulkApprove: brand.BrandApprove != 'Yes' ? true : false,
              brandBulkReject: brand.BrandApprove == 'Yes' ? true : false,
              programConfigId: brand.programConfigId
            };
          }

          groupedData[brandRequestId].brandData.push(brand);
        });

        let data: any[] = [];
        // Convert groupedData to an array and push to retData.items
        data = Object.values(groupedData);
        data.forEach((item) => {
          retData.items.unshift({
            ...item,
            innerTableData: new MatTableDataSource(item.brandData)
          });
        });
        setTimeout(() => {
          retData.totalCount = retData.items.length;
          this.multiRowTableData = retData;
          this.multipleRowCommonTable.loadItems(retData);
          this.onBrandToggleList(this.toggleSwitchStr);
          this.isLoading = false;
        }, 100);
      });
  }

  onActionClickEmit(ev: any) {
    let key = ev.action.key;
    console.log(ev.data);

    switch (key) {
      case 'approve':
        break;
      case 'delete':
        break;
      case 'fileCertificate':
        this.viewFileData(ev.data);
        break;
      case 'upload':
        this.onUpload(ev.data);
        break;
      case 'viewProcess':
        this.onViewProcess(ev?.data);
        break;
      case 'view':
        this.dialog.open(ViewPdfComponent, {
          width: '80%',
          data: {
            src: ev.data.file.fileUrl
          }
        });
        break;
      case 'approveBrand':
        const { innerTableData, ...restOfData } = ev.data;

        this.dataService.setProgramBrand(restOfData);
        this._router.navigate([
          `programs/brands/approve/${ev.data.BrandRequestId}`
        ]);
        break;
      case 'rejectAllBrand':
        this.onRejectAllBrand(ev.data);
        break;
      case 'viewInnerFile':
        this.dialog.open(ViewPdfComponent, {
          width: '80%',
          data: {
            src: ev.data.fileUrl
          }
        });
        break;
      case 'viewInnerMark':
        this.dialog.open(ViewImageComponent, {
          width: '40%',
          data: {
            image: ev.data.brandMarksUrl
          }
        });
        break;

      case 'downloadInnerMark':
        this.onDownloadMark(ev.data.brandMarksUrl, 'logo');
        break;
      case 'downloadInnerFile':
        this.onDownloadMark(ev.data.file.fileUrl, 'file');
        break;
      case 'brandUnpublish':
        this.onBrandUploadWebsite(ev.data, 'BrandUnpublish');
        break;
      case 'brandPublish':
        this.onBrandUploadWebsite(ev.data, 'BrandPublish');
        break;
      default:
        break;
    }
  }

  onDownloadMark(ev: any, filename: string) {
    const fileUrl = ev;

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename; // You can set the desired filename here
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  }
  onOpenCoreFormulationAgreement() {
    this._router.navigate(['']);
  }

  onAddClick() {}

  viewFileData(ev: any) {
    const programImg =
      environment.mediaUrl +
      ev?.programConfigDetails?.attachment[0]?.dir +
      '/' +
      ev?.programConfigDetails?.attachment[0]?.fileName;
    const data = {
      programImg: programImg,
      companyName: ev?.CompanyName || '-',
      brandName: ev?.brandName || 'brand',
      grade: ev?.VisGrade || '-',
      specification: ev?.SpecName || '-',
      registrationId: ev?.brandNameRegistrationId || '-',
      expiryDate: moment(ev?.createdAt).format('YYYY MMMM DD') || '-'
    };

    let dialogRef = this.dialog.open(PreviewBrandCertificateComponent, {
      data: {
        data: data,
        isLoading: false
      },
      autoFocus: false,
      width: '100%'
    });
  }

  onDownload(ev: any) {
    const programImg =
      environment.mediaUrl +
      ev?.programConfigDetails?.attachment[0]?.dir +
      '/' +
      ev?.programConfigDetails?.attachment[0]?.fileName;
    const data = {
      programImg: programImg,
      companyName: ev?.companyName || '-',
      brandName: ev?.brandName || 'brand',
      grade: ev?.programConfigId || '-',
      specification: ev?.programName || '-',
      registrationId: ev?.brandNameRegistrationId || '-',
      expiryDate: ev?.createdAt || '-'
    };
    this.programIfcService.downloadPDF(data);
  }
  onUpload(ev: any) {
    const data = {
      companyName: ev?.companyName,
      brandName: ev?.brandName || 'brand',
      grade: ev?.programConfigId,
      specification: ev?.programName,
      registrationId: ev?.brandNameRegistrationId,
      expiryDate: ev?.createdAt
    };
    this.programIfcService.onUpload(data);
  }

  onViewProcess(ev: any) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: 45,
        type: 'brand',
        programConfig: ev.programConfigDetails.id,
        userId: ev.createdBy
      }
    };
    let programData = {
      programId: ev.programConfigDetails.id
    };

    this.dataService.setProgramByIDData(programData);
    this._router.navigate([`/signed-program/add/`], navigationExtras);
  }

  onApproveBrand(id: any) {
    Swal.fire({
      title: 'Brand Approve',
      text: `Are you sure you want to Approve Brand?`,
      icon: 'info',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        let data: any = {
          BrandStatus: 'Active',
          BrandApprove: 'Yes'
        };

        try {
          let resp = firstValueFrom(
            this.programBrandingService.updateBrand(id, data)
          );
          this.commonTableRef.initLoadItems();
        } catch (err) {
          this.appSettingsService.handleError(err);
        }
      }
    });
  }

  onRejectAllBrand(ev: any) {
    const { innerTableData, ...restOfData } = ev;

    // Open the dialog and assign the result to dialogRef
    const dialogRef: MatDialogRef<RejectBrandComponent> = this.dialog.open(
      RejectBrandComponent,
      {
        width: '60%',
        data: restOfData
      }
    );

    // Subscribe to the dialog close event
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        setTimeout(() => {
          this.multipleRowCommonTable.initLoadItems();
        }, 1000);
      }
    });
  }
  columns: any[] = [];
  onBrandToggleList(ev: any) {
    this.columns = [];
    this.isLoading = true;
    if (ev == 'report') {
      this.toggleSwitchStr = 'report';

      this.columns = this.innerColumns;
      setTimeout(() => {
        this.burgerActions = [];
        this.burgerActions = [
          { key: 'view', icon: 'mat:description', label: 'View Form' }
        ];
        if (this.userCheck.checkUser()) {
          this.burgerActions.push(
            { key: 'brandUnpublish', icon: 'mat:publish', label: 'Unpublish' },
            { key: 'brandPublish', icon: 'mat:publish', label: 'Publish' },
            {
              key: 'viewInnerMark',
              icon: 'mat:remove_red_eye',
              label: 'View QR Code'
            },
            {
              key: 'downloadInnerMark',
              icon: 'mat:download',
              label: 'Download QR Code'
            }
          );
        } else {
          this.burgerActions.push(
            {
              key: 'viewInnerMark',
              icon: 'mat:remove_red_eye',
              label: 'View  QR Code'
            },
            {
              key: 'downloadInnerMark',
              icon: 'mat:download',
              label: 'Download QR Code'
            }
          );
        }
        this.multipleRowCommonTable.loadItems(this.tableData);
        this.isLoading = false;
      }, 500);
    } else {
      this.toggleSwitchStr = 'brands';
      this.columns = this.multiTableColumns;
      setTimeout(() => {
        this.multipleRowCommonTable.loadItems(this.multiRowTableData);
        this.burgerActions = [];

        this.burgerActions = [
          { key: 'view', icon: 'mat:description', label: 'View Form' }
        ];
        if (this.userCheck.checkUser()) {
          this.burgerActions.push(
            {
              key: 'approveBrand',
              icon: 'mat:edit',
              label: 'Approve'
            },
            {
              key: 'rejectAllBrand',
              icon: 'mat:edit',
              label: 'Reject All Brand'
            }
          );
        }
        this.isLoading = false;
      }, 500);
    }
  }
  dynamicColumnBrands: any[];
  async getListViewColumns(page: string) {
    try {
      const resp = await firstValueFrom(
        this.listConfigurationService.getlistViewColumns(page)
      );
      let respItems: any = resp.items[0].columns;
      let string = this.userCheck.checkUser() ? 'isCQA' : 'isCompany';
      const filteredColumn = respItems.tableColumn.filter(
        (i: any) => i['isCompany'] || i['isSecondary']
      );
      const newColumn = this.userCheck.checkUser()
        ? respItems.tableColumn.map((item: any) => ({
            label: item.label,
            property: item.property,
            type: 'text',
            visible: item.isCQA ? true : false,
            tableName: item.tableName
          }))
        : filteredColumn.map((item: any) => ({
            label: item.label,
            property: item.property,
            type: 'text',
            visible: item.isCompany ? true : item.isSecondary ? false : false,
            tableName: item.tableName
          }));

      this.innerColumns = newColumn.length > 0 ? newColumn : this.innerColumns;
      setTimeout(() => {
        this.innerColumns.push(
          {
            label: 'Published status',
            property: 'uploadToWebsite',
            type: 'text',
            visible: true
          },
          {
            label: 'Actions',
            property: 'actions',
            type: 'button',
            visible: true
          }
        );
        this.columns = this.multiTableColumns;
      }, 200);
    } catch (err) {
      this.appSettingsService.handleError(err);
    }
  }

  onBrandUploadWebsite(ev: any, str: string) {
    Swal.fire({
      title: `Brand ${str == 'BrandUnpublish' ? 'unpublish' : 'publish'}`,
      text: `Are you sure you want to ${
        str == 'BrandUnpublish' ? 'unpublish' : 'publish'
      } Brand?`,
      icon: 'info',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        let data: any = {
          uploadToWebsite: str == 'BrandUnpublish' ? false : true
        };

        try {
          let resp = firstValueFrom(
            this.programBrandingService.updateBrand(ev.brandId, data)
          );
          this.multipleRowCommonTable.initLoadItems();
        } catch (err) {
          this.appSettingsService.handleError(err);
        }
      }
    });
  }
}
