import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from 'src/app/core/shared/shared.module';

@Component({
  selector: 'vex-terms-service',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './terms-service.component.html',
  styleUrls: ['./terms-service.component.scss']
})
export class TermsServiceComponent {
  isChecked = false;
  cmsData: any;
  constructor(
    public dialogRef: MatDialogRef<TermsServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isChecked = data.isChecked;
    this.cmsData = data.data;
  }

  onSubmit() {
    this.dialogRef.close({ isChecked: true });
  }
}
