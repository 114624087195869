import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';

import {
  catchError,
  debounceTime,
  map,
  merge,
  Observable,
  of as observableOf,
  of,
  ReplaySubject,
  startWith
} from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { MatPaginator } from '@angular/material/paginator';
import { SharedModule } from 'src/app/core/shared/shared.module';
import {
  QueryParamsModel,
  QueryResultsModel,
  TableColumn
} from '../common-table/common-table.component';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { VexPageLayoutComponent } from '@vex/components/vex-page-layout/vex-page-layout.component';
import { VexPageLayoutHeaderDirective } from '@vex/components/vex-page-layout/vex-page-layout-header.directive';
import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexPageLayoutContentDirective } from '@vex/components/vex-page-layout/vex-page-layout-content.directive';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSelectChange } from '@angular/material/select';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FilterColumnsComponent } from '../filter-columns/filter-columns.component';
import { ViewPdfComponent } from 'src/app/pages/master-data/program-config/components/view-pdf/view-pdf.component';
import { CommonAutocompleteComponent } from '../common-autocomplete/common-autocomplete.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { brands } from 'src/app/pages/list-configuration/column-configuration-list/components/list-configuration-filter';

@Component({
  selector: 'vex-multiple-row-common-table',
  standalone: true,
  imports: [
    SharedModule,
    VexPageLayoutComponent,
    VexPageLayoutHeaderDirective,
    VexBreadcrumbsComponent,
    VexPageLayoutContentDirective,
    CommonAutocompleteComponent
  ],
  templateUrl: './multiple-row-common-table.component.html',
  styleUrls: ['./multiple-row-common-table.component.scss']
})
export class MultipleRowCommonTableComponent {
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>>;

  pageIndex = 0;
  pageSize = 50;
  pageSizeOptions: number[] = [50, 100, 150, 200];
  @Input() title = '';
  @Input() columns: TableColumn<any>[] = [];
  @Input() innerColumns: TableColumn<any>[] = [];
  @Input() actions: Array<any> = [];
  @Input() burgerActions: Array<any> = [];
  @Input() burgerInnerActions: Array<any> = [];

  @Input() tableConfig = {
    showSearch: true,
    showPagination: true,
    actionFields: true,
    showDateRange: false,
    showDateFilter: false,
    showStaffFilter: false,
    showOrderTypeFilter: false,
    showPlatformFilter: false,
    isAddButton: false,
    isExportButton: false
  };
  @Output() onLoadItemsEvent = new EventEmitter();
  @Output() onActionClickEmit = new EventEmitter();
  expandedElement: any;
  selectedItem: any;
  //public searchCtrl = new FormControl('');
  queryResults: QueryResultsModel;
  dataSource: MatTableDataSource<any>;
  public filteredAndPagedIssues: Observable<any[]> = of([]);

  @Input() showActiveInActive: boolean = false;
  @Input() showWidthFullWidth: boolean = true;
  @Input() showBreadCrumbs: boolean = true;
  @Input() enablePadding: boolean = true;
  @Input() showActiveArchiveList: boolean = false;
  @Input() enableInfoIcon: boolean = false;
  @Input() showCQACompanyList: boolean = false;
  @Input() toggleViewString: string = 'CQA';
  layoutCtrl = new FormControl('boxed');
  toggleCtrl = new FormControl('Active');
  toggleArchiveListCtrl = new FormControl('active');
  toggleCQACompanyListCtrl = new FormControl('cqa');
  @Input() crumbsMenu = 'Dashboard';
  public selection = new SelectionModel<any>(true, []);
  @Input() showFilterBtn = false;
  @Input() filterDropdown: boolean = false;

  @Output() onActiveInactive = new EventEmitter();
  @Output() onActiveArchiveList = new EventEmitter();
  @Output() onCQACompanyList = new EventEmitter();
  deleteIcon: boolean;
  infoIcon: boolean;
  filterButtonText = 'Filter';
  filterButtonIcon = 'mat:filter_alt';
  showFilter = false;
  @Input() sampleTypeList: any[] = [];
  @Input() showSampleTypeFilter: boolean = false;
  @Input() programList: any[] = [];
  @Input() showProgramFilter: boolean = false;
  @Output() onChangeProgram = new EventEmitter();

  @Input() statusList: any[] = [
    { name: 'Pending', value: 'Pending' },
    { name: 'On Hold', value: 'on-hold' },
    { name: 'Completed', value: 'completed' }
  ];
  @Input() paymentStatusList: any[] = [
    { name: 'Initiated', value: 'PaymentInitiated' },
    { name: 'Completed', value: 'PaymentCompleted' }
  ];
  @Input() paymentMethodList: any[] = [
    { name: 'Card', value: 'card' },
    { name: 'ACH', value: 'ach' },
    { name: 'Wire Transfer', value: 'wire-transfer' }
  ];
  @Input() showStatusFilter: boolean = false;
  @Output() onChangeSampleStatus = new EventEmitter();
  @Input() showPaymentStatusFilter: boolean = false;
  @Output() onChangePaymentStatus = new EventEmitter();
  @Input() showPaymentMethodStatusFilter: boolean = false;
  @Output() onChangePaymentMethodStatus = new EventEmitter();

  @Input() companyList: any[] = [];
  @Input() showCompanyFilter: boolean = false;
  @Output() onChangeCompany = new EventEmitter();
  public datePickerForm: FormGroup;

  @Input() showAddButton = false;
  @Input() showExportButton = false;
  @Output() onExportClick = new EventEmitter();
  public activeRoute? = '';
  @Input() childActiveRoute: string = '';
  @Output() onAddClick = new EventEmitter();
  @Output() onChangeSampleType = new EventEmitter();
  @Output() onDateRangeChange = new EventEmitter();
  @Input() showDateRange: boolean = false;
  @Input() showSearchCtrl: boolean = true;
  @Input() showPagination: boolean = true;
  @Output() onAddOptionClick = new EventEmitter();
  @Input() showAddOptionButton = false;
  @Input() showAddOptionButtonValues: any[];
  @Input() showBrandToggleList: boolean = false;
  toggleBrandToggleListCtrl = new FormControl('brands');
  @Output() onBrandToggleList = new EventEmitter();
  @Input() isLoading: boolean = false;
  @Input() showStatusBadgeFilter: boolean = false;
  @Input() badgeFilterItems: any[] = [];
  activeItem: string | null = 'all';
  @Output() onBadgeFilterEmit = new EventEmitter();
  @Output() onBtnActionClick = new EventEmitter();

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  searchCtrl = new UntypedFormControl();

  constructor(
    private router: Router,
    public appSettingsService: AppSettingsService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.datePickerForm = new FormGroup({
      start: new FormControl(),
      end: new FormControl()
    });
    this.activeRoute = this.childActiveRoute
      ? this.childActiveRoute
      : this.router.url.split('/').pop();

    this.datePickerForm.valueChanges
      .pipe(debounceTime(200))
      .subscribe((event) => {
        if (event.start && event.end) {
          this.changeDate(event);
        } else if (!event.start && !event.end) {
          this.changeDate(null);
        }
      });

    this.toggleCtrl.valueChanges.subscribe((i) => {
      this.onActiveInactive.emit(i);
    });
    this.toggleArchiveListCtrl.valueChanges.subscribe((i) => {
      this.onActiveArchiveList.emit(i);
    });
    this.toggleCQACompanyListCtrl.valueChanges.subscribe((i) => {
      this.onCQACompanyList.emit(i);
    });
    this.toggleBrandToggleListCtrl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((i) => {
        this.expandedElement = null;
        this.selectedItem = null;
        this.onBrandToggleList.emit(i);
        this.router.navigate([], { queryParams: { active: i } });
      });
    this.searchCtrl.valueChanges
      .pipe(debounceTime(20), takeUntilDestroyed(this.destroyRef))
      .subscribe((search) => {
        this.router.navigate([], { queryParams: { search } });
        this.initLoadItems(false);
      });
  }
  ngAfterViewInit() {
    this.filteredAndPagedIssues = this.data$.pipe(
      startWith({ totalCount: 0, items: [] }),
      map((data) => {
        // Flip flag to show that loading has finished.

        this.resultsLength = data.totalCount;
        console.log(data);
        return data.items;
      }),
      catchError(() => {
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        return observableOf([]);
      })
    );
    console.log(this.filteredAndPagedIssues);
    console.log(this.resultsLength);
  }
  get visibleColumns() {
    // let data = localStorage.getItem(this.router.url);
    // if (data) this.columns = JSON.parse(data);
    // const userHasPermission = this.navigationService.isHavePermission(
    //   this.activeRoute,
    //   ['Edit', 'View', 'Delete']
    // );
    // if (userHasPermission) {
    //   return this.columns
    //     .filter((column) => column.visible)
    //     .map((column) => column.property);
    // } else {
    //   // Exclude the "Actions" column if the user doesn't have permission
    //   return this.columns
    //     .filter((column) => column.visible && column.property !== 'actions')
    //     .map((column) => column.property);
    // }

    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  get innerColumn() {
    return this.innerColumns
      .filter((column) => column?.visible)
      .map((column) => column?.property);
  }

  public getDataByType(row: any, column: any) {
    let data = '';

    switch (column?.type) {
      case 'money':
        if (row[column.property] !== '-') {
          data = '$' + row[column.property];
        } else {
          data = row[column.property];
        }

        break;
      case 'date':
        data = row[column.property];
        break;
      default:
        data =
          row[column.property] !== '' &&
          row[column.property] !== null &&
          row[column.property] !== undefined
            ? row[column.property]
            : '-';

        break;
        break;
    }

    return data;
  }

  toggleInnerTable(element: any): void {
    if (this.expandedElement === element) {
      // If the row is already expanded, close it
      this.expandedElement = null;

      this.selectedItem = null;
    } else {
      // Expand the row and set the selected item
      this.expandedElement = element;
      this.selectedItem = element;
    }
  }
  filterConfiguration(): any {
    const filter: any = {};
    return filter;
  }
  public subject$: ReplaySubject<QueryResultsModel> =
    new ReplaySubject<QueryResultsModel>();
  public data$: Observable<QueryResultsModel> = this.subject$.asObservable();

  initLoadItems(firstLoad: boolean = false) {
    const pageIndex = this.paginator ? this.paginator.pageIndex : 0;
    const pageSize = this.paginator ? this.paginator.pageSize : 0;
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort ? this.sort.direction.toUpperCase() : 'DESC',
      this.sort ? this.sort.active : 'id',
      pageIndex,
      firstLoad ? 10 : pageSize,
      this.searchCtrl ? this.searchCtrl.value : ''
    );
    let queryResults = {
      items: [],
      totalCount: 0,
      error: ''
    };
    this.dataSource = new MatTableDataSource(undefined);
    this.subject$.next(queryResults);
    this.isLoading = true;
    this.onLoadItemsEvent.emit(queryParams);
  }
  resultsLength: number;
  public loadItems(queryResults: QueryResultsModel) {
    this.queryResults = queryResults;
    this.dataSource = new MatTableDataSource(queryResults.items);
    this.subject$.next(queryResults);
    this.isLoading = false;
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onOpenSortColumnTable() {
    let dialogRef: MatDialogRef<FilterColumnsComponent>;
    dialogRef = this.dialog.open(FilterColumnsComponent, {
      width: '40%',
      data: {
        columns: this.columns,
        childActiveRoute: this.childActiveRoute
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {
      let data = localStorage.getItem(this.router.url);
      if (data) this.columns = JSON.parse(data);
    });
  }
  onCreate() {
    this.onAddClick.emit();
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    if (this.showFilter) {
      this.filterButtonText = 'Clear';
      this.filterButtonIcon = 'mat:close';
    } else {
      this.filterButtonIcon = 'mat:filter_alt';
      this.filterButtonText = 'Filter';
    }
    this.resetEventValues();
  }
  viewFileData(ev: any) {
    let dialogRef: MatDialogRef<ViewPdfComponent>;
    dialogRef = this.dialog.open(ViewPdfComponent, {
      width: '80%',
      data: {
        src: ev
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {});
  }
  onViewFileData(title: any, ev: any) {
    let dialogRef: MatDialogRef<ViewPdfComponent>;
    dialogRef = this.dialog.open(ViewPdfComponent, {
      width: '80%',
      data: {
        src: ev,
        title: title
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {});
  }
  changeSampleType(event: MatSelectChange) {
    this.onChangeSampleType.emit(event.value);
  }
  changeProgram(event: MatSelectChange) {
    this.onChangeProgram.emit(event.value);
  }

  resetEventValues() {
    this.onChangeSampleType.emit({ type: '' });
    this.onChangeProgram.emit({ type: '' });
    this.onChangeSampleStatus.emit({ type: '' });
    this.onChangePaymentMethodStatus.emit({ type: '' });
    this.onChangePaymentStatus.emit({ type: '' });
    this.onChangeCompany.emit({ type: '' });
    this.onDateRangeChange.emit({ type: '' });
  }

  changeSampleStatus(event: MatSelectChange) {
    this.onChangeSampleStatus.emit(event.value);
  }
  changePaymentMethod(event: MatSelectChange) {
    this.onChangePaymentMethodStatus.emit(event.value);
  }
  changePaymentStatus(event: MatSelectChange) {
    this.onChangePaymentStatus.emit(event.value);
  }
  changeCompany(event: MatAutocompleteSelectedEvent) {
    this.onChangeCompany.emit(event.option.value);
  }
  onExport() {
    this.onExportClick.emit({ key: 'export', label: 'Export' });
    this.loadExportItems();
  }
  public changeDate(date: any) {
    this.onDateRangeChange.emit({ dates: date });
  }
  public loadExportItems() {
    const data = [];
    if (this.queryResults) {
      for (const row of this.queryResults.items) {
        const temp: any = {};
        for (const colum of this.columns) {
          if (colum.property !== 'actions') {
            temp[colum.label] = row[colum.property] ? row[colum.property] : '';
          }
        }
        data.push(temp);
      }
    }

    this.appSettingsService.exportCSV(data, this.title);
  }

  filterActionButtons(row: any) {
    let filteredActions = this.actions;
    if (row.quotation != 'Yes') {
      filteredActions = filteredActions.filter(
        (item) => item.property !== 'quotationForm'
      );
    }
    return filteredActions;
  }
  filterBurgerActions(row: any): any[] {
    let filteredActions = this.burgerActions;
    if (row.formType !== 'Interactive') {
      filteredActions = filteredActions.filter(
        (action) => action.key !== 'onViewFormFields'
      );
    }
    if (row.isFormVariant) {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'view' && item.key !== 'download'
      );
    } else {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'onViewFormVariants'
      );
    }
    if (!row.brandBulkApprove) {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'approveBrand'
      );
    }
    if (row.uploadToWebsite == 'unPublished') {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'brandUnpublish'
      );
    }
    if (row.uploadToWebsite == 'Published') {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'brandPublish'
      );
    }
    return filteredActions;
  }
  onActionClick(action: any, data: any) {
    this.onActionClickEmit.emit({ action, data });
  }
  filterBurgerInnerActions(row: any) {
    let filteredActions = this.burgerInnerActions;

    if (row.BrandApprove != 'Yes') {
      filteredActions = filteredActions.filter(
        (item) =>
          item.key !== 'downloadCertificate' &&
          item.key !== 'fileCertificate' &&
          item.key !== 'viewInnerMark' &&
          item.key !== 'downloadInnerMark' &&
          item.key !== 'brandUnpublish' &&
          item.key !== 'brandPublish'
      );
    }

    if (row.uploadToWebsite == 'unPublished') {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'brandUnpublish'
      );
    }
    if (row.uploadToWebsite == 'Published') {
      filteredActions = filteredActions.filter(
        (item) => item.key !== 'brandPublish'
      );
    }

    return filteredActions;
  }
  getTextColor(value: string, row = null) {
    switch (value) {
      case 'Delivered':
      case 'accepted':
      case 'Active':
      case 'Approved':
      case 'Both':
      case 'global':
      case 'Patient':
      case 'Store':
      case 'Adult':
      case 'Amount':
      case 'Yes':
      case 'Metrc':
      case 'Sale':
      case 'Received':
      // case 'Ready For Sale':
      case 'Close':
      case 'Supplier Label':
      case 'Cash':
      case 'Opened':

      case 'true':
      case 'Paid':
      case 'Online/In-store':
        return 'success';
      case 'Placed':
      case 'created':
      case 'Debit':
      case 'Pay-In':
      case 'Pending':
      // case 'Dispensary Admin':
      case 'Percentage':
      case 'Push Notification':
      case 'PO':
      case 'Moved':
      case 'Fulfillment':
        return 'placed';
      case 'Shipped':
      case 'Online':
      case 'Medical':
      case 'SMS':
      case 'Consumer':

      case 'Caregiver':
      case 'ExternalPatient':
      case 'Buy One Get One':
      case 'Adjusted':
      case 'Ready For Pickup':
      case 'Tip':
      case 'Credit':
      case 'Standard':
      case 'Manual':
        return 'shipped';
      case 'Cancelled':
      case 'Cancelled By Customer':
      case 'Cancelled By Seller':
      case 'Voided':
      case 'Declined':
      case 'Expired':
      case 'Inactive':
      case 'Pay-Out':
      case 'Open':
      case 'Closed':
      case 'White Label':
      case 'false':
      case 'Destroyed':
        return 'danger';
      case 'In transit':
      case 'support':
      case 'Redeemed':
      case 'location':

      case 'No':
      case 'POS':
        return 'warning';

      case 'Not Ready For Sale':
        return 'not_ready_for_sale';

      case 'Ready For Sale':
        return 'ready_for_sale';

      case 'Closed':
        return 'close_badge';

      case 'Dispensary Admin':
        return 'dispensary_admin';

      default:
        return 'grey';
    }
  }

  getChipColor(value: string, row = null) {
    let clas = '';
    switch (value) {
      case 'Delivered':
      case 'Active':
      case 'Approved':
      case 'Both':
        clas = 'primary';
        break;
      case 'Placed':
      case 'created':
      case 'Pending':
      case 'location':
      case 'global':
      case 'Push Notification':
        clas = 'accent';
        break;
      case 'Shipped':
      case 'SMS':
        clas = 'secondary';
        break;
      case 'Cancelled':

      case 'Declined':
      case 'Inactive':
        clas = 'warn';
        break;
      case 'In transit':
      case 'support':
        clas = 'warn';
        break;
      default:
        break;
    }

    return clas;
  }
  onAddOptionCreate(ev: any) {
    this.onAddOptionClick.emit({ data: ev });
  }
  isActiveStatus(item: any): boolean {
    return this.activeItem === item;
  }
  onClickStatus(item: any) {
    if (this.activeItem === item.value) {
      this.activeItem = item.value;
    } else {
      this.activeItem = item.value;
    }
    this.onBadgeFilterEmit.emit({ dates: item });
  }
  onDownload(ev: any) {
    const fileUrl = ev;

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'file'; // You can set the desired filename here
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  }
  onBtnAction(ev: any) {
    this.onBtnActionClick.emit({ data: ev });
  }
}
