<vex-page-layout>
  <ng-container *ngIf="enablePadding">
    <div
      class="flex flex-col items-start justify-center cqa-page-layout-header gap-2"
      [class.pb-[3rem]]="enablePadding">
      <div
        [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-6]="layoutCtrl.value === 'fullwidth'"
        class="w-full flex flex-col sm:flex-row justify-between">
        <div *ngIf="showBreadCrumbs">
          <h1 class="title mt-0 mb-1 text-2xl font-bold">{{ title }}</h1>
          <vex-breadcrumbs [crumbs]="[crumbsMenu, title]"></vex-breadcrumbs>
        </div>

        <!-- <div class="hidden sm:block" *ngIf="showWidthFullWidth">
          <mat-button-toggle-group
            [formControl]="layoutCtrl"
            class="mt-4 sm:mt-0">
            <mat-button-toggle value="boxed">Boxed</mat-button-toggle>
            <mat-button-toggle value="fullwidth">Full-Width</mat-button-toggle>
          </mat-button-toggle-group>
        </div> -->
        <div class="hidden sm:block" *ngIf="showActiveInActive">
          <mat-button-toggle-group
            [formControl]="toggleCtrl"
            class="mt-4 sm:mt-0">
            <mat-button-toggle value="Active"
              >{{ showActiveInActiveObj.active }}
              <span
                class="badge-active"
                *ngIf="showActiveInActiveObj.activeCount != ''"
                >{{ showActiveInActiveObj.activeCount }}</span
              ></mat-button-toggle
            >
            <mat-button-toggle value="Inactive"
              >{{ showActiveInActiveObj.inactive }}
              <span
                class="badge-inactive"
                *ngIf="showActiveInActiveObj.inactiveCount != ''"
                >{{ showActiveInActiveObj.inactiveCount }}</span
              >
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="hidden sm:block" *ngIf="showActiveArchiveList">
          <mat-button-toggle-group
            [formControl]="toggleArchiveListCtrl"
            class="mt-4 sm:mt-0">
            <mat-button-toggle value="active">Active</mat-button-toggle>
            <mat-button-toggle value="archive">Archive</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="showCQACompanyList">
          <mat-button-toggle-group
            [formControl]="toggleCQACompanyListCtrl"
            class="mt-4 sm:mt-0">
            <mat-button-toggle value="cqa">{{
              toggleViewString
            }}</mat-button-toggle>
            <mat-button-toggle value="company">Company</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="container h-9">
        <div
          *ngIf="showStatusBadgeFilter"
          class="flex flex-row items-center gap-2">
          <div *ngFor="let item of badgeFilterItems">
            <!-- <span
            class="rounded-lg px-2 py-1 text-xs flex-none bg-white text-[#17aeff] font-bold">
          </span> -->
            <button
              mat-stroked-button
              [ngClass]="{
                '!bg-[#17aeff] !text-white !font-medium': isActiveStatus(
                  item.value
                ),
                '!bg-white !text-gray-400 !font-medium': !isActiveStatus(
                  item.value
                )
              }"
              (click)="onClickStatus(item)">
              <span> {{ item.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <vex-page-layout-content
    [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-6]="layoutCtrl.value === 'fullwidth'"
    [class.-mt-6]="enablePadding">
    <div class="overflow-auto" [class.-mt-16]="enablePadding">
      <div class="h-16 border-b sticky left-0 flex items-center">
        <h2
          *ngIf="selection.hasValue()"
          class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
          <span
            >{{ selection.selected.length + " " + title
            }}<span *ngIf="selection.selected.length > 1">s</span>
            selected</span
          >
        </h2>

        <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
          <!-- <button
            color="primary"
            mat-icon-button
            matTooltip="Delete selected"
            type="button">
            <mat-icon svgIcon="mat:delete"></mat-icon>
          </button> -->

          <!-- <button
            color="primary"
            mat-icon-button
            matTooltip="Another action"
            type="button">
            <mat-icon svgIcon="mat:folder"></mat-icon>
          </button> -->
        </div>

        <!-- <mat-form-field subscriptSizing="dynamic">
          <mat-icon matIconPrefix svgIcon="mat:search"></mat-icon>
          <input
            matInput
            [formControl]="searchCtrl"
            placeholder="Search&hellip;"
            type="text" />
        </mat-form-field> -->

        <!-- <mat-form-field subscriptSizing="dynamic">
          <input
            matInput
            [formControl]="searchCtrl"
            placeholder="Search&hellip;"
            type="text" />
          <mat-icon matIconSuffix svgIcon="mat:search"></mat-icon>
        </mat-form-field> -->

        <div class="search-form">
          <input
            type="text"
            class="form-control"
            name="email"
            [formControl]="searchCtrl"
            placeholder="Search&hellip;" />
          <span><mat-icon matIconSuffix svgIcon="mat:search"></mat-icon></span>
        </div>
        <span class="flex-1"></span>

        <button
          *ngIf="showFilterBtn"
          (click)="toggleFilter()"
          class="hidden sm:inline-flex ml-3"
          mat-stroked-button>
          <mat-icon [svgIcon]="filterButtonIcon"></mat-icon>
          <span class="ml-2">{{ filterButtonText }}</span>
        </button>
        <button
          (click)="onOpenSortColumnTable()"
          class="ml-4 flex-none"
          mat-icon-button
          matTooltip="Filter Columns"
          type="button">
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>

        <button
          class="ml-4 flex-none"
          color="primary"
          mat-stroked-button
          matTooltip="Add {{ title }}"
          type="button"
          (click)="onCreate()"
          *ngIf="showAddButton">
          <mat-icon svgIcon="mat:add"></mat-icon>Add
        </button>
        <!-- option add button -->
        <button
          class="hidden sm:inline-flex ml-3"
          mat-flat-button
          [color]="'primary'"
          [matMenuTriggerFor]="addOptionMenu"
          *ngIf="showAddOptionButton">
          <mat-icon
            class="icon-size-5"
            [svgIcon]="'heroicons_solid:plus'"></mat-icon>
          <span class="ml-2">
            {{ "Add New" }}
          </span>
        </button>
        <mat-menu #addOptionMenu>
          <ng-container>
            <button
              mat-menu-item
              *ngFor="let i of showAddOptionButtonValues"
              (click)="onAddOptionCreate(i)">
              {{ i.name }}
            </button>
          </ng-container>
        </mat-menu>
        <button
          *ngIf="showExportButton"
          (click)="onExport()"
          class="hidden sm:inline-flex ml-3"
          mat-stroked-button
          matTooltip="Export {{ title }}">
          <mat-icon class="icon-size-5" [svgIcon]="'mat:download'"></mat-icon>
          <span class="ml-2">Export</span>
        </button>
      </div>
      <div @fadeInUp class="bg-app-bar px-6 h-auto" *ngIf="showFilter">
        <div class="filter py-2 flex flex-row justify-end gap-4">
          <mat-form-field *ngIf="showSampleTypeFilter">
            <mat-label>Sample Type</mat-label>
            <mat-select
              placeholder="Filter by sample type"
              (selectionChange)="changeSampleType($event)">
              <mat-option
                *ngFor="let option of sampleTypeList"
                [value]="option"
                >{{ option.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showProgramFilter">
            <mat-label>Program</mat-label>
            <mat-select
              placeholder=" Filter by program"
              (selectionChange)="changeProgram($event)">
              <mat-option *ngFor="let option of programList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showStatusFilter">
            <mat-label>Sample Status</mat-label>
            <mat-select
              placeholder="Filter by Sample status"
              (selectionChange)="changeSampleStatus($event)">
              <mat-option *ngFor="let option of statusList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showPaymentStatusFilter">
            <mat-label>Payment Status</mat-label>
            <mat-select
              placeholder="Filter by Payment Status"
              (selectionChange)="changePaymentStatus($event)">
              <mat-option
                *ngFor="let option of paymentStatusList"
                [value]="option"
                >{{ option.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showPaymentMethodStatusFilter">
            <mat-label>Payment Method</mat-label>
            <mat-select
              placeholder="Filter by Payment Method"
              (selectionChange)="changePaymentMethod($event)">
              <mat-option
                *ngFor="let option of paymentMethodList"
                [value]="option"
                >{{ option.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <!-- 
          <mat-form-field *ngIf="showCompanyFilter">
            <mat-label>Company</mat-label>
            <mat-select
              placeholder="Filter by Company"
              (selectionChange)="changeCompany($event)">
              <mat-option *ngFor="let option of companyList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field> -->
          <vex-common-autocomplete
            *ngIf="showCompanyFilter"
            [valueList]="companyList"
            [showLabel]="false"
            [placeholder]="'Search by company'"
            (optionSelected)="changeCompany($event)">
          </vex-common-autocomplete>
          <mat-form-field *ngIf="showDateRange">
            <mat-date-range-input
              [formGroup]="datePickerForm"
              [rangePicker]="campaignOnePicker"
              [comparisonStart]="datePickerForm.value.start"
              [comparisonEnd]="datePickerForm.value.end">
              <input
                matStartDate
                placeholder="Start date"
                formControlName="start"
                appDateInputFormat
                maxlength="10" />
              <input
                matEndDate
                placeholder="End date"
                formControlName="end"
                appDateInputFormat
                maxlength="10" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="campaignOnePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
          </mat-form-field>
          <vex-date-input-filter
            *ngIf="showMultiDateRange"
            [formData]="dateRangeFormList"
            (formValuesChanged)="
              changeMultipleDate($event)
            "></vex-date-input-filter>
        </div>
      </div>
      <table
        [dataSource]="filteredAndPagedIssues"
        class="table-responsive overflow-x-auto full-wid scrollable-element"
        mat-table
        matSort
        (matSortChange)="resetPaging()">
        <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <!-- <ng-container matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              color="primary">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" class="w-4" mat-cell>
            <mat-checkbox
              (change)="$event ? selection.toggle(row) : null"
              (click)="$event.stopPropagation()"
              [checked]="selection.isSelected(row)"
              color="primary">
            </mat-checkbox>
          </td>
        </ng-container> -->

        <ng-container matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelecteds()"
              [indeterminate]="selection.hasValue() && !isAllSelecteds()">
            </mat-checkbox>
          </th>

          <td *matCellDef="let row" class="w-4" mat-cell>
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="
                $event ? selection.toggle(row) : null; updateSelectedData()
              "
              [checked]="selection.isSelected(row)"
              [disabled]="row.stock_quantity === 0">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container
            *ngIf="column.type === 'text' || column.type === 'money'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <!-- first -->
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <span [class]="getTextColor(row[column.property])">
                {{ getDataByType(row, column) }}
              </span>
            </td>

            <!-- first -->
          </ng-container>

          <ng-container
            *ngIf="column.type === 'stock-quantity'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>

            <!-- new -->

            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              mat-cell
              class="cell-container">
              <ng-container
                *ngIf="
                  column.property === 'stock' || column.property === 'quantity'
                ">
                <div class="flex items-center">
                  <div>
                    {{ row[column.property] }}
                  </div>
                  <ng-container
                    *ngIf="
                      column.property === 'stock' &&
                      row['stock'] >= row['quantity']
                    ">
                    <!-- High stock -->
                    <div
                      class="flex items-end ml-2 w-1 h-4 bg-green-100 rounded overflow-hidden">
                      <div class="flex w-full h-full bg-green-400"></div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      column.property === 'stock' &&
                      row['stock'] < row['quantity'] / 2
                    ">
                    <!-- Low stock -->
                    <div
                      class="flex items-end ml-2 w-1 h-4 bg-red-200 rounded overflow-hidden">
                      <div class="flex w-full h-1/3 bg-red-600"></div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      column.property === 'stock' &&
                      row['stock'] >= row['quantity'] / 2 &&
                      row['stock'] < row['quantity']
                    ">
                    <!-- Medium stock -->
                    <div
                      class="flex items-end ml-2 w-1 h-4 bg-orange-200 rounded overflow-hidden">
                      <div class="flex w-full h-2/4 bg-orange-400"></div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  column.property !== 'stock' && column.property !== 'quantity'
                ">
                {{ getDataByType(row, column) }}
              </ng-container>
            </td>

            <!-- new -->
          </ng-container>

          <ng-container
            *ngIf="column.type === 'price'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div class="flex flex-row justify-between items-center">
                <span>US$</span><span> {{ getDataByType(row, column) }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'percentage'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div class="flex flex-row justify-between items-center">
                <span> {{ getDataByType(row, column) }}</span>
                <span>%</span>
              </div>
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'link'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              mat-cell
              style="color: #0000ee; cursor: pointer"
              (click)="onLinkClick(row)">
              {{ getDataByType(row, column) }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'file'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <button
                mat-icon-button
                [matTooltip]="'View Form'"
                (click)="viewFileData(row['name'], row[column.property])">
                <mat-icon svgIcon="mat:remove_red_eye"></mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'id'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              S.No &nbsp;&nbsp;
            </th>
            <td
              *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses"
              mat-cell>
              1
              <!-- {{ paginator?.pageIndex * paginator?.pageSize + (i + 1) }} -->
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'mobile'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'date'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] }}
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'icon-btn'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <button mat-icon-button>
                <!-- matTooltip="Reset" -->
                <mat-icon
                  [class]="row[column.property] ? 'success-text' : 'warn-text'"
                  >{{
                    row[column.property] ? "check_circle" : "cancel"
                  }}</mat-icon
                >
              </button>
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'badge'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="badge-style"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>

            <td
              *matCellDef="let row"
              class="text-center"
              [ngClass]="column.cssClasses"
              mat-cell>
              <div (click)="$event.stopPropagation()">
                <div
                  class="btn-group btn-group-sm badge-btn"
                  *ngIf="row[column.property]">
                  <span
                    class="badge badge-success"
                    [class]="getTextColor(row[column.property])">
                    {{ row[column.property] }}
                  </span>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- /**select**/ -->

          <ng-container
            *ngIf="column.type === 'check-box'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell class="text-center uppercase">
              <mat-checkbox
                [checked]="selection.hasValue()"
                [indeterminate]="selection.hasValue()">
              </mat-checkbox>
            </th>

            <td
              *matCellDef="let row"
              class="text-center"
              [ngClass]="column.cssClasses"
              mat-cell>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="
                  $event ? selection.toggle(row) : null; updateSelectedData()
                "
                [checked]="selection.isSelected(row)"
                [disabled]="row.stock_quantity === 0">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- /**select**/ -->

          <ng-container
            *ngIf="column.type === 'chip'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" mat-cell>
              <div
                (click)="$event.stopPropagation()"
                class="flex items-center gap-1">
                <div
                  [ngClass]="getTextColor(row[column.property])"
                  class="rounded px-2 py-1 font-extrabold text-xs flex-none">
                  {{ row[column.property] }}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'status'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <mat-select
                (click)="$event.stopPropagation()"
                [(value)]="row[column.property]">
                <mat-option value="In-Progress">In Progress</mat-option>
                <mat-option value="Completed">Completed</mat-option>
              </mat-select>
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'status-badge'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div
                (click)="$event.stopPropagation()"
                fxLayoutAlign="start center"
                fxLayoutGap="4px">
                <div class="btn-group btn-group-sm">
                  <button
                    mat-flat-button
                    [ngClass]="
                      row[column.property] === 1
                        ? 'btn btn-success'
                        : 'btn btn-danger'
                    "
                    class="btn btn-round btn-xs">
                    {{ row[column.property] === 1 ? "Active" : "InActive" }}
                  </button>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'switch'"
            [matColumnDef]="column.property">
            <!-- <th
                                        *matHeaderCellDef
                                        class="uppercase text-center"
                                        mat-header-cell
                                        mat-sort-header
                                        style="text-align: center"
                                    >
                                        {{ column.label }}
                                    </th> -->
            <th *matHeaderCellDef mat-header-cell class="text-center uppercase">
              {{ column.label }}
            </th>
            <td
              style="text-align: center"
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              mat-cell
              [matTooltip]="
                row['role_id'] === 4 ? 'First archive in dispensary' : ''
              "
              matTooltipPosition="above"
              (click)="$event.stopPropagation()">
              <div
                [matTooltip]="
                  row?.isActive === 1 || row?.isActive === true
                    ? 'Active'
                    : 'In-Active'
                ">
                <label class="switch">
                  <input
                    type="checkbox"
                    [checked]="row[column.property]"
                    (click)="changeStatus($event, row, column)"
                    [disabled]="
                      row['coupon_status'] === 'Expired' ||
                      row['coupon_status'] === 'Redeemed' ||
                      row['role_id'] === 4 ||
                      row['is_primary']
                    " />
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'star'"
            [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <ng-container *ngFor="let item of [1, 2, 3, 4, 5]">
                <ng-container
                  *ngIf="item <= row[column.property]; else elseTemplate">
                  <span
                    class="material-icons text-warning"
                    style="color: #fe9a03"
                    >star</span
                  >
                </ng-container>
                <ng-template #elseTemplate>
                  <span class="material-icons text-warning">star_border</span>
                </ng-template>
              </ng-container>
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'userLogo'"
            [matColumnDef]="column.property"
            class="uppercase">
            <th *matHeaderCellDef mat-header-cell>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" mat-cell>
              <div class="flex flex-row gap-4">
                <img
                  [src]="row[column.property]"
                  alt="logo"
                  class="object-cover rounded-full h-9 w-9"
                  loading="lazy"
                  (error)="handleImageError($event)" />
                <div class="text-center uppercase w-max">
                  {{ row["userName"] }}
                </div>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <!-- Contact Column -->
        <ng-container matColumnDef="contact">
          <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" mat-cell>
            <div class="flex">
              <a
                (click)="$event.stopPropagation()"
                class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
                mat-icon-button>
                <!-- <mat-icon [icIcon]="icPhone" size="18px"></mat-icon> -->
              </a>

              <a
                (click)="$event.stopPropagation()"
                class="w-8 h-8 leading-none flex items-center justify-center ml-1 hover:bg-hover text-teal bg-teal-light"
                mat-icon-button>
                <!-- <mat-icon [icIcon]="icMail" size="18px"></mat-icon> -->
              </a>

              <a
                (click)="$event.stopPropagation()"
                class="w-8 h-8 leading-none flex items-center justify-center ml-1 hover:bg-hover text-green bg-green-light"
                mat-icon-button>
                <!-- <mat-icon [icIcon]="icMap" size="18px"></mat-icon> -->
              </a>
            </div>
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell class="w-max"></th>
          <td *matCellDef="let row" mat-cell>
            <div class="flex flex-row items-center gap-2 text-secondary w-max">
              <!-- actions viewFile, Download file -->
              <ng-container *ngFor="let action of filterActionButtons(row)">
                <button
                  *ngIf="action.key == 'viewFile' && row[action.property]"
                  mat-flat-button
                  [matTooltip]="'View File'"
                  class="!text-white !bg-gray-400 min-w-36 buttonHover"
                  (click)="
                    viewFileData(
                      action?.label || row['name'],
                      row[action.property]
                    )
                  ">
                  <span>{{ action.label ? action.label : "View Form" }}</span>
                  <!-- <mat-icon
                    iconPositionStart
                    matButtonIcon
                    svgIcon="mat:remove_red_eye"></mat-icon> -->
                </button>
                <button
                  *ngIf="action.key == 'download' && row[action.property]"
                  mat-flat-button
                  [matTooltip]="'Download File'"
                  class="!text-white !bg-gray-400 buttonHover"
                  (click)="onDownload(row[action.property])">
                  <span>Download</span>
                  <mat-icon
                    iconPositionStart
                    matButtonIcon
                    svgIcon="mat:download"></mat-icon>
                </button>
                <button
                  *ngIf="action.key == 'btn'"
                  mat-flat-button
                  [matTooltip]="action.label || ''"
                  class="!text-white !bg-gray-400 min-w-36 buttonHover"
                  (click)="onBtnAction(row)">
                  <span>{{ action.label }}</span>
                  <mat-icon
                    *ngIf="action?.icon"
                    iconPositionStart
                    matButtonIcon
                    [svgIcon]="action?.icon"></mat-icon>
                </button>
              </ng-container>
              <button
                *ngIf="
                  filterBurgerActions(row) &&
                  filterBurgerActions(row).length > 0
                "
                (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: row }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
                <mat-icon svgIcon="mat:settings"></mat-icon>
              </button>
            </div>
            <mat-menu
              #actionsMenu="matMenu"
              xPosition="before"
              yPosition="below">
              <ng-container *ngFor="let item of filterBurgerActions(row)">
                <!-- [disabled]="row.isApproved == 1 && item.label == 'Approve'" -->
                <button mat-menu-item (click)="onActionClick(item, row)">
                  <mat-icon [svgIcon]="item.icon"></mat-icon>
                  <span>{{ item.label }}</span>
                </button>
              </ng-container>
            </mat-menu>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <!-- (click)="goToView(row); $event.stopPropagation()" -->
        <tr
          *matRowDef="let row; columns: visibleColumns"
          class="hover:bg-hover trans-ease-out"
          [class.add-items-row]="userAddItems1"
          mat-row
          (click)="addRowValue(row); updateSelectedData()"></tr>

        <tr
          [hidden]="
            (queryResults &&
              queryResults.items &&
              queryResults.items.length > 0) ||
            isLoading
          "
          mat-footer-row
          *matFooterRowDef="['noRecord']"></tr>
        <tr
          [hidden]="!isLoading"
          mat-footer-row
          class="p-0"
          *matFooterRowDef="['spinner']"></tr>

        <ng-container matColumnDef="spinner">
          <td
            mat-footer-cell
            *matFooterCellDef
            class="text-center p-0"
            [attr.colspan]="columns.length">
            <div id="loader" class="text-center py-6">
              <div class="loader mx-auto mb-4">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p class="text-gray-700 text-base">One moment please...</p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecord">
          <td
            mat-footer-cell
            *matFooterCellDef
            class="text-center"
            [attr.colspan]="columns.length">
            No records found.
          </td>
        </ng-container>
      </table>
      <mat-paginator
        *ngIf="tableConfig.showPagination"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        [length]="resultsLength"
        class="sticky left-0"></mat-paginator>
    </div>
  </vex-page-layout-content>
</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="mat-menu-item block">
    <mat-checkbox
      (click)="$event.stopPropagation()"
      [(ngModel)]="column.visible"
      color="primary">
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>

<!-- <mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button mat-menu-item>
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>Modify</span>
    </button>
    <button mat-menu-item>
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>Delete</span>
    </button>
  </ng-template>
</mat-menu> -->
