import { Component, Inject } from '@angular/core';
import { CommonModule, NgFor, TitleCasePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { SharedModule } from 'src/app/core/shared/shared.module';
@Component({
  selector: 'vex-filter-columns',
  standalone: true,
  imports: [
    MatButtonModule,
    NgFor,
    MatListModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    TitleCasePipe,
    DragDropModule,
    SharedModule
  ],
  templateUrl: './filter-columns.component.html',
  styleUrls: ['./filter-columns.component.scss']
})
export class FilterColumnsComponent {
  onSubmit() {}

  public columns: any[] = [];
  currentUrl: string;
  groupColumns: any;
  isExpanded: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<FilterColumnsComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.columns = this.data.columns;
  }

  ngOnInit(): void {
    this.currentUrl = this.data.childActiveRoute
      ? this.data.childActiveRoute
      : this.router.url;
      console.log(this.currentUrl)
    this.groupColumns = this.groupByTable();
  }

  toggleColumnVisibility(column: any) {
    this.data.columns.visible = !column.visible;
    localStorage.setItem(this.currentUrl, JSON.stringify(this.data.columns));
  }
  sortable(data: any) {
    // Access the new order of columns from the event
    const newOrder = data.newIndex;
    localStorage.setItem(this.currentUrl, JSON.stringify(this.columns));
  }
  setToDefault() {
    localStorage.removeItem(this.currentUrl);
  }
  /**
   * Close
   */
  saveAndClose(): void {
    // Close the dialog

    this.dialogRef.close();
  }

  drop(event: CdkDragDrop<unknown>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    localStorage.setItem(this.currentUrl, JSON.stringify(this.columns));
  }

  // Group columns by tableName
  groupByTable(): any {
    const grouped = this.columns.reduce((acc: any, column: any) => {
      const tableName = column?.tableName;
      if (tableName) {
        if (!acc[tableName]) {
          acc[tableName] = { tableName, columns: [], isExpanded: false };
        }
        acc[tableName].columns.push(column);
      } else {
        if (!acc['other']) {
          acc['other'] = {
            tableName: 'Columns',
            columns: [],
            isExpanded: false
          };
        }
        if (column.property != 'actions') {
          acc['other'].columns.push(column);
        }
      }

      return acc;
    }, {});
    return Object.values(grouped);
  }
}
