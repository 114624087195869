// auth.guard.ts
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AppSettingsService } from '../app-settings.service';
import { UserAuthenticationService } from '../navigation/user-authantication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAdmin implements CanActivate {
  constructor(
    private router: Router,
    private appSettingsService: AppSettingsService,
    public userCheck: UserAuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.userCheck.checkUser()) {
      return true;
    } else {
      const userRole = localStorage.getItem('role');
      // Redirect to sign-in page if not authenticated
      this.router.navigate(['/login']);
      return false;
    }
  }

  private isUserAuthenticated(): boolean {
    const userRole = localStorage.getItem('role');
    if (!userRole) {
      return false;
    }
    return true;
  }
}
