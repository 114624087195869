import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/core/shared/shared.module';
import {
  NgxExtendedPdfViewerComponent,
  NgxExtendedPdfViewerModule,
  NgxExtendedPdfViewerService
} from 'src/app/pdf/ngx-extended-pdf-viewer';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { LoaderComponent } from 'src/app/layouts/components/loader/loader.component';

@Component({
  selector: 'vex-view-pdf',
  standalone: true,
  imports: [SharedModule, NgxExtendedPdfViewerModule, LoaderComponent],
  providers: [NgxExtendedPdfViewerService],
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent {
  isLoading: boolean = true;
  src: any;
  title: string = 'View Form';
  pdfData: any;
  disableFiled: any;
  @ViewChild(NgxExtendedPdfViewerComponent, { static: false })
  private pdfViewer!: NgxExtendedPdfViewerComponent;
  constructor(
    private pdfService: NgxExtendedPdfViewerService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ViewPdfComponent>,
    private _elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    this.src = data?.src;
    this.title = data?.title;
    this.pdfData = data?.loadPdfData;
    this.disableFiled = data?.disableFiled;
    dialogRef.beforeClosed().subscribe((result) => {
      this.pdfViewer.ngOnDestroy();
    });
  }

  public formData: any = {};
  onPageRendered(ev: any) {
    setTimeout(async () => {
      this.isLoading = false;
      if (this.data && this.data?.loadPdfData) {
        this.formData = {
          ...this.pdfData,
          ZipCode: this.pdfData?.CoPostalCode
        };
        // Loop through each key in pdfData and update the corresponding form element
        const inputElements = this._elementRef.nativeElement.querySelectorAll(
          'input, select, textarea'
        );

        // Loop through each element and disable it
        inputElements.forEach(
          (
            inputElement:
              | HTMLInputElement
              | HTMLSelectElement
              | HTMLTextAreaElement
          ) => {
            inputElement.disabled = true;
          }
        );

        this.cdr.detectChanges();
      }

      if (this.disableFiled) {
        // Loop through each key in pdfData and update the corresponding form element
        const inputElements = this._elementRef.nativeElement.querySelectorAll(
          'input, select, textarea'
        );

        // Loop through each element and disable it
        inputElements.forEach(
          (
            inputElement:
              | HTMLInputElement
              | HTMLSelectElement
              | HTMLTextAreaElement
          ) => {
            inputElement.disabled = true;
          }
        );
        this.setReadonlyFieldsContinuously();
      }
      this.cdr.detectChanges();
    }, 0);
  }
  signatureKeys = ['CQA02Signature', 'CQA03Signature'];
  // signature = this.signatureKeys.map((i:any)=>{
  //   return i
  // })
  @ViewChild('signatureInput') signatureInput: ElementRef;
  ngAfterViewInit() {
    // this.enableSignatureClick();
  }
  enableSignatureClick() {
    this.signatureInput.nativeElement.addEventListener('click', () => {
      const editorButton = document.getElementById('primaryEditorInk');
      if (editorButton) {
        editorButton.click();
      }
    });
  }

  setReadonlyFieldsContinuously() {
    const observer = new MutationObserver(() => {
      const LicenseFeeInput = this._elementRef.nativeElement.querySelector(
        `input[name="LicenseFee"], select[name="LicenseFee"], textarea[name="LicenseFee"]`
      );
      const LicenseRenewalFeeInput =
        this._elementRef.nativeElement.querySelector(
          `input[name="LicenseRenewalFee"], select[name="LicenseRenewalFee"], textarea[name="LicenseRenewalFee"]`
        );

      if (LicenseFeeInput) {
        LicenseFeeInput.readOnly = true;
      }
      if (LicenseRenewalFeeInput) {
        LicenseRenewalFeeInput.readOnly = true;
      }

      // Trigger change detection to ensure the view is updated
      this.cdr.detectChanges();
    });

    // Observe changes in the child elements of the PDF container
    observer.observe(this._elementRef.nativeElement, {
      childList: true,
      subtree: true
    });
  }
}
