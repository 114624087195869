<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    {{ title }}
  </h2>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content class="flex flex-row items-center justify-center">
  <div class="!mt-1">
    <img
      [src]="imageUrl"
      alt="label Image"
      class="h-40 w-72 object-contain p-2 rounded-sm"
      style="transform: scale(1.4)" />
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flex flex-row justify-end">
  <div><button mat-button mat-dialog-close type="button">Cancel</button></div>
</mat-dialog-actions>
