<app-loader [loading]="isLoading"></app-loader>
<div class="flex items-center justify-between" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    {{ "Reject Brand" }}
  </h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content>
  <form
    [formGroup]="form"
    *ngIf="form"
    class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <ng-container formArrayName="brands">
      <ng-container *ngFor="let brandControl of brands.controls; let i = index">
        <div>
          <p class="custom-label">Brand Names</p>
          <div class="flex flex-row gap-2">
            {{ getBrandGroupNameControl("brandName", i).value }}
          </div>
        </div>
        <!-- <mat-form-field>
          <mat-label>Notes</mat-label>
          <input
            matInput
            [formControl]="getBrandGroupNameControl('notes', i)"
            placeholder="Enter notes" />
        </mat-form-field> -->
      </ng-container>
    </ng-container>
  </form>
  <mat-form-field>
    <mat-label>Notes</mat-label>
    <input matInput [formControl]="notesCtrl" placeholder="Enter notes" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">Cancel</button>
  <button color="primary" mat-flat-button type="submit" (click)="onSubmit()">
    Submit
  </button>
</mat-dialog-actions>
