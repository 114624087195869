import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ListConfigurationService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}

  getColumns(queryParams: any): Observable<any> {
    return this.http.get('getColumns?table=' + queryParams, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (resp && resp.data && resp?.error?.code <= 0) {
          let rows = resp.data;
          for (const item of rows) {
            let temp: any = {
              ...item
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }
        return retData;
      })
    );
  }
  getlistViewColumns(queryParams: any): Observable<any> {
    return this.http
      .get('listViewColumns?class=' + queryParams, httpOptions)
      .pipe(
        map((resp: any) => {
          let retData: any = {
            items: [],
            totalCount: 0,
            error: ''
          };

          if (
            resp &&
            resp.data &&
            Array.isArray(resp.data.rows) &&
            resp?.error?.code <= 0
          ) {
            let rows = resp.data.rows;
            for (const item of rows) {
              let temp: any = {
                ...item,
                id: item.id
              };
              retData.items.push(temp);
            }

            retData.totalCount = resp.data.count;
          } else {
            retData.error = resp?.error?.message ? resp.error.message : '';
          }
          return retData;
        })
      );
  }

  createlistConfiguration(data: any): Observable<any> {
    return this.http.post('listViewColumns', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  updatelistConfiguration(id: any, data: any): Observable<any> {
    return this.http.put('listViewColumns/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  getBylistConfigurationId(id: any): Observable<any> {
    return this.http.get('listViewColumns/' + id).pipe(
      map((resp: any) => {
        let listConfiguration: any;
        if (resp && resp.data) {
          let data = resp.data;
          listConfiguration = data;
        }
        return listConfiguration;
      })
    );
  }

  deletelistConfiguration(id: any): Observable<any> {
    return this.http.delete('listViewColumns/' + id, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  //handle error messages
  handleError(err: any) {
    this.appSettingsService.handleError(err);
  }

  getAlllistConfiguration(params: any): Observable<any> {
    let param = this.appSettingsService.queryStringFormatWithoutOffset(params);
    return this.http.get('listViewColumns/list?' + param, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          retData.items = resp.data.rows.map((item: any) => ({ ...item }));
          // Assuming resp.data contains the JSON data
          // retData.items = resp.data.rows.map((permission: any) => ({
          //   id: parseInt(permission.id),
          //   name: resp.data.name,
          //   menuID: parseInt(permission?.menuID),
          //   menuActionID: parseInt(permission?.menuActionId),
          //   menuAction: {
          //     id: parseInt(permission.menuAction?.id),
          //     name: permission.menuAction?.name,
          //     isDelete: permission.menuAction?.isDelete
          //   }
          // }));

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }
}
