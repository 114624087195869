import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { firstValueFrom } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramIfcService {
  uploadFile: any;
  constructor(private appSettingsService: AppSettingsService) {}

  generateIFC08Html(data: any): string {
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    };

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const formattedExpiryDate = formatDate(data.expiryDate);
    return `
    <div id="certificate" class="ifc p-8 bg-white pb-0 w-[1200px] mx-auto">
    <div class="border-double border-4 border-[#3b54a4] p-4">
        <div class="flex items-center gap-24">
        <img src='${data.programImg}' alt="Program Image" class="h-26 w-fit" style='height:100px; width:fit-content;'/>
            <h2 class="text-4xl font-bold">International Fluids Consortium</h2>
        </div>
        <h1 class="text-center text-[56px] font-semibold" style="font-family: Goudy Old Style">
            Certificate of Registration
        </h1>
        <h4 class="text-center mb-2">Issued to</h4>
        <h2 class="text-5xl text-center">${data.companyName}</h2>
        <p class="mx-auto font-normal text-base m-0 leading-5" style="font-family: Arial, Helvetica, sans-serif;">
            This document certifies that the licensed company named above is
            authorized to use the International Fluids Consortium (IFC)
            Certification Mark in association with a registered fluid product,
            as specified below:
        </p>
        <table class="mx-auto my-2">
            <tr>
                <td class="text-xl">Product Brand Name:</td>
                <td class="font-semibold text-xl">${data.brandName}</td>
            </tr>
            <tr>
                <td class="text-xl">SAE Viscosity Grade:</td>
                <td class="font-semibold text-xl">${data.grade}</td>
            </tr>
            <tr>
                <td class="text-xl">IFC Specification:</td>
                <td class="font-semibold text-xl">${data.specification}</td>
            </tr>
            <tr>
                <td class="text-xl">Brand Name Registration ID:</td>
                <td class="font-semibold text-xl">${data.registrationId}</td>
            </tr>
            <tr>
                <td class="text-xl">Expiration Date:</td>
                <td class="font-semibold text-xl">${formattedExpiryDate}</td>
            </tr>
        </table>
        <p class="mx-auto font-normal text-base m-0 leading-5" style="font-family: Arial, Helvetica, sans-serif;">
            This fluid has been tested and meets the rigorous quality
            requirements for the IFC Specification above. The licensed company
            agrees to comply with the quality standards set forth in the IFC
            Licensing and Fluid Certification Program. The Brand Name
            Registration ID shown above must appear on all container labels of
            the certified product.
        </p>
        <p class="mx-auto font-normal text-base" style="font-family: Arial, Helvetica, sans-serif;">
            For questions regarding this Certificate of Registration, please
            contact the Center for Quality Assurance.
        </p>
        <div class="flex justify-between items-center">
            <div>
                <img
                    src="/assets/img/doc_img/CQA.png"
                    class="h-15 w-fit"
                    alt=""
                />
                <p class="mb-1 w-[42%]">
                    Center for Quality Assurance 4800 James Savage Road Midland,
                    MI 48642 USA
                </p>
                <div class="flex gap-2">
                    <p class="mb-1">E-mail :</p>
                    <p class="mb-1">Admin&#64;IFCStandards.org</p>
                </div>
                <div class="flex gap-2">
                    <p class="mb-1">Website:</p>
                    <p class="mb-1">CenterForQA.com/IFC</p>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-8">
                <div class="flex">
                    <label for="" class="w-44 text-2xl">Name:</label>
                    <input
                        class="font-bold border-b border-black px-2 w-72"
                        type="text"
                        value="Rebecca Cox"
                    />
                </div>
                <div class="flex">
                    <label for="" class="w-44 text-2xl">Title:</label>
                    <input
                        class="font-bold border-b border-black px-2 w-72"
                        type="text"
                        value="President"
                    />
                </div>
                <div class="flex">
                    <label for="" class="w-44 text-2xl">Signature:</label>
                    <input
                        class="font-bold border-b border-black px-2 w-72"
                        type="text"
                        value="Rebecca Cox"
                    />
                </div>
                <div class="flex">
                    <label for="" class="w-44 text-2xl">Date:</label>
                    <input
                        class="font-bold border-b border-black px-2 w-72"
                        type="text"
                        value="${formattedDate}"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="flex justify-between items-center">
        <p class="m-0 p-2">Copyright © 2024 Center for Quality Assurance</p>
        <p class="m-0 p-2">
            Form IFC08 Product Brand Name Registration Certificate Rev. 20231214
        </p>
    </div>
</div>
    `;
  }
  downloadPDF(data: any, shouldDownload: boolean = true): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const htmlContent = this.generateIFC08Html(data);
      const div = document.createElement('div');
      div.innerHTML = htmlContent;
      document.body.appendChild(div);
      const certificateElement = div.querySelector('#certificate');
      if (certificateElement) {
        html2canvas(certificateElement as HTMLElement).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('l', 'mm', 'a4', true);
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          if (shouldDownload) {
            pdf.save('certificate.pdf');
          } else {
            const pdfBlob = pdf.output('blob');
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String =
                reader.result?.toString().split(',')[1] || '';
              resolve(base64String);
            };
            reader.readAsDataURL(pdfBlob);
            this.uploadFile = pdf.output('blob');
          }
          document.body.removeChild(div);
        });
      } else {
        reject('Certificate element not found');
        document.body.removeChild(div);
      }
    });
  }

  async onUpload(data: any) {
    this.downloadPDF(data, false);
    new Promise<void>((resolve) => {
      setTimeout(() => {
        if (this.uploadFile) {
          const formData = new FormData();
          formData.append('file', this.uploadFile);
          resolve(
            firstValueFrom(this.appSettingsService.postAttachment(formData))
          );
        } else {
          console.error('PDF file not generated');
          resolve();
        }
      }, 500);
    });
  }

  viewPDF(data: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.downloadPDF(data, false);
      setTimeout(() => {
        if (this.uploadFile) {
          resolve(this.uploadFile);
        } else {
          reject('PDF file not generated');
        }
      }, 500);
    });
  }
}
