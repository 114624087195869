import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from '../shared.module';

@Component({
  selector: 'vex-view-image',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss']
})
export class ViewImageComponent {
  imageUrl: any;
  title: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ViewImageComponent>
  ) {
    this.imageUrl = data?.image;
    this.title = data?.label;
  }
}
