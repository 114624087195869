import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private matSnackBar: MatSnackBar,
    private ngZone: NgZone
  ) {}

  /**
   * Handles an error by displaying a snackbar notification and logging the error to the console.
   * @param error - The error object to handle.
   */
  handleError(error: unknown): void {
    // Run the following code inside the Angular zone to ensure proper change detection
    this.ngZone.run(() => {
      // Display a snackbar notification with the message "Error found" and a "Close" button
      this.matSnackBar.open('Error found', 'Close', { duration: 2000 });
    });

    // Log the error object to the console for debugging purposes
    console.error('error :>> ', error);
  }
}
