import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Location } from '@angular/common';
import { Observable, forkJoin, BehaviorSubject, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap
} from 'rxjs/operators';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { parseResponse } from './helpers';
import { environment } from 'src/assets/environments/environment.prod';
import { ConfirmDialogComponent } from '../layouts/components/confirm-dialog/confirm-dialog.component';
import { Toast, ToastrService } from 'ngx-toastr';
import {
  VexColorScheme,
  VexConfigName
} from '@vex/config/vex-config.interface';
import { VexConfigService } from '@vex/config/vex-config.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
import { ExportToCsv } from 'export-to-csv';
import { SelectFormVariantComponent } from '../pages/master-data/form-type/component/select-form-variant/select-form-variant.component';
import { ViewPdfComponent } from '../pages/master-data/program-config/components/view-pdf/view-pdf.component';
import { PDFDocument, PDFTextField } from 'pdf-lib';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  // source for observable
  public userSettingsSource = new BehaviorSubject<any>(null);
  // observable stream
  public userSettings$ = this.userSettingsSource.asObservable();
  private userDispensarySource = new BehaviorSubject(null);
  public userDispensary$ = this.userDispensarySource
    .asObservable()
    .pipe(distinctUntilChanged());
  public tokenName = 'ng-token';
  public roleID = 'role';
  public categoriesList = [];
  public shiftTimingList = [];
  public salesTypeList = [];
  public orderStatusList = [];
  public prodCategoriesList = [];
  public metrcCategoriesList = [];
  public stainsList = [];
  public prodSubCategoriesList = [];
  public uomList = [];
  public menuList = [];
  public productMenu = [];
  public menuItems = [];
  public planMenuItems = [];
  public rolesList = [];
  public staffRolesList = [];
  public licenseList = [];
  public locationList = [];
  public brandList = [];
  public tagList = [];
  public dispensaryList = [];
  public passwordWarningMessage =
    'Password should have 8 characters and must include at least one uppercase, one lowercase, a numeric number 0-9, and a special character ! @  # $ % ^ & * - ?';
  constructor(
    private http: HttpClient,
    protected localStorag: StorageMap,
    private location: Location,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private readonly configService: VexConfigService,
    private dialog: MatDialog
  ) {
    this.userSettingsSource = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('userProfile') || '{}')
    );
    this.userSettings$ = this.userSettingsSource.asObservable();
  }

  public setUserSettings(data: any) {
    this.userSettingsSource.next(data);
  }
  public setUserDispensary(data: any) {
    this.userDispensarySource.next(data);
  }
  public userData() {
    return this.userSettingsSource.value;
  }

  setUserToken(userInfo: any, token: string) {
    this.setUserRole(userInfo);

    return this.localStorag.set(this.tokenName, token);
  }
  setUserRole(userInfo: any) {
    let role = '';
    if (userInfo.role_id.id == 1) {
      role = 'ROLE_ADMIN';
    } else if (userInfo.role_id.id == 2) {
      role = 'ROLE_SUB_ADMIN';
    } else if (userInfo.role_id.id == 3 && !userInfo.company) {
      role = 'ROLE_ADMIN_STAFF';
    } else if (userInfo.role_id.id == 3 && userInfo.company) {
      role = 'ROLE_SUB_ADMIN_STAFF';
    }
    // switch (userInfo) {
    //   case 1:
    //     role = 'ROLE_ADMIN';
    //     break;
    //   case 2:
    //     role = 'ROLE_SUB_ADMIN';
    //     break;
    //   case 3:
    //     role = 'ROLE_STAFF';
    //     break;
    //   default:
    //     break;
    // }
    return localStorage.setItem('role', role);
  }

  getUserRole() {
    return localStorage.getItem(this.roleID);
  }

  getUserByID(id: any) {
    return this.http.get('user/profile/' + id).pipe(
      map((resp: any) => {
        let Users: any;
        if (resp && resp.data) {
          let userAttachment = resp.data?.attachment?.find(
            (i: any) => i.class == 'MyProfile'
          );
          let logo =
            environment.mediaUrl +
            userAttachment?.dir +
            '/' +
            userAttachment?.fileName;
          let data = { ...resp.data, userImgUrl: logo };
          Users = data;
        }
        return Users;
      })
    );
  }

  get isAdmin(): boolean {
    return localStorage.getItem('role') === 'ROLE_ADMIN';
  }
  get isDispensary(): boolean {
    return localStorage.getItem('role') === 'ROLE_USER';
  }
  get isStaff(): boolean {
    return localStorage.getItem('role') === 'ROLE_STAFF';
  }

  isHavePermission(resourcetype: string, actions: any) {
    const menu = this.menuList.find(
      (planMenu: any) => resourcetype === planMenu.state
    );
    // const menu = this.menuList.find((planMenu) => {
    //   let flag = false;
    //   if (planMenu.type === 'link') {
    //     flag = resourcetype === planMenu.state;
    //   } else {
    //     planMenu.children.map((child) => {
    //       flag = child.state === resourcetype;
    //     });
    //   }
    //   return flag ? planMenu : false;
    // });
    return (
      this.isAdmin ||
      this.isDispensary ||
      (this.isStaff && menu && menu[actions])
    );
  }

  getUserByToken() {
    return this.http.get(environment.apiUrl + '/me/users').pipe(
      map((resp: any) => {
        let user: any;
        if (resp.data) {
          const rawData = resp.data;
          if (rawData && rawData.id) {
            user = rawData;
          }
          this.setUserSettings(user);
        }

        return user;
      })
    );
  }

  /**
   * isAdmin
   */
  public getLoggedinUserId(): boolean {
    let id = null;
    if (
      this.userSettingsSource.value &&
      this.userSettingsSource.value.role_id
    ) {
      id = this.userSettingsSource.value.id;
    }
    return id;
  }

  /**
   * showSuccessMessage
   */
  public showSuccessMessage(message = '', title = '', options: any = {}) {
    const defaultOptions = {
      timeout: 3000,
      verticalPosition: 'top'
    };

    options = { ...defaultOptions, ...options };

    // this.snackbar.open(message, 'OK', {
    //   duration: options.timeout,
    //   verticalPosition: options.verticalPosition
    // });
  }

  /**
   * showErrorMessage
   */
  public showErrorMessage(message = '', title = '', options: any = {}) {
    const defaultOptions = {
      duration: 3000,
      verticalPosition: 'top'
    };

    options = { ...defaultOptions, ...options };

    // this.snackbar.open(message, 'OK', options);
  }

  /**
   * showErrorMessage
   */
  public showWarningMessage(message = '', title = '', options: any = {}) {
    const defaultOptions = {
      timeout: 3000
    };

    options = { ...defaultOptions, ...options };

    // this.snackbar.open(message, 'OK', {
    //   duration: options.timeout
    // });
  }

  public queryStringFormat(queryParams: any) {
    const reqParams: any = {
      offset:
        queryParams.pageNumber > 0
          ? queryParams.pageNumber * queryParams.pageSize
          : 0,
      limit: queryParams.pageSize || '',
      sortField: 'id',
      sortOrder: 'DESC'
    };

    if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
      reqParams.filter = queryParams.filter;
    }

    if (queryParams.queryString) {
      reqParams.searchString = queryParams.queryString;
    }

    if (queryParams.sortField && queryParams.sortOrder) {
      reqParams.sortOrder = queryParams.sortOrder;
      reqParams.sortField = queryParams.sortField;
    }

    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(reqParams[k]);
      })
      .join('&');

    return queryString;
  }
  public queryStringFormatWithoutOffset(queryParams: any) {
    let req: any;

    if (queryParams && Object.keys(queryParams).length > 0) {
      req = JSON.parse(queryParams.filter);
    }

    let reqParams: any = {};
    if (req?.where?.type) {
      reqParams.type = `${req?.where?.type}`;
    }

    if (req?.where?.processFlow) {
      reqParams.processFlow = `${req?.where?.processFlow}`;
    }

    if (req?.where?.processFlowId) {
      reqParams.id = `${req?.where?.processFlowId}`;
    }
    if (req?.where?.userId) {
      reqParams.userId = `${req?.where?.userId}`;
    }
    if (req?.where?.programConfigId) {
      reqParams.programConfigId = `${req?.where?.programConfigId}`;
    }
    if (req?.where?.hasOwnProperty('is_delete')) {
      reqParams.is_delete = false;
    }
    if (req?.where?.hasOwnProperty('isCompany')) {
      reqParams.isCompany = req?.where?.isCompany;
    }
    if (req?.where?.companyId) {
      reqParams.companyId = req?.where?.companyId;
    }
    if (req?.where?.formIds) {
      reqParams.formIds = req?.where?.formIds;
    }
    if (req?.where?.childTableName) {
      reqParams.childTableName = req?.where?.childTableName;
    }
    if (req?.where?.sampleId) {
      reqParams.sampleId = req?.where?.sampleId;
    }

    if (req?.where?.isCQARole) {
      reqParams.isCQA = req?.where?.isCQARole;
    }
    if (req?.where?.programConfigId) {
      reqParams.programConfigId = req?.where?.programConfigId;
    }

    if (req?.where?.isFormulatorBlender) {
      reqParams.isFormulatorBlender = req?.where?.isFormulatorBlender;
    }
    if (req?.where?.isRebrander) {
      reqParams.isRebrander = req?.where?.isRebrander;
    }
    if (req?.where?.FormulationType) {
      reqParams.FormulationType = req?.where?.FormulationType;
    }
    if (req?.where?.BrandApprove) {
      reqParams.BrandApprove = req?.where?.BrandApprove;
    }
    if (req?.where?.isDraft) {
      reqParams.isDraft = req?.where?.isDraft;
    }

    if (req?.where?.isIFC05) {
      reqParams.isIFC05 = req?.where?.isIFC05;
    }
    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return k + '=' + reqParams[k];
      })
      .join('&');

    return queryString;
  }

  // public queryStringFormatWithoutOffset(queryParams: any) {
  //   let req: any;

  //   if (queryParams && Object.keys(queryParams).length > 0) {
  //     req = JSON.parse(queryParams.filter);
  //   }

  //   const reqParams: any = {};
  //   if (req?.where) {
  //     Object.entries(req.where).forEach(([key, value]) => {
  //       reqParams[key] = `${value}`;
  //     });
  //   }

  //   const queryString = Object.keys(reqParams)
  //     .map((k) => `${k}=${reqParams[k]}`)
  //     .join('&');

  //   return queryString;
  // }

  public queryStrFormat(queryParams: any) {
    var req;
    if (Object.keys(queryParams?.filter).length > 0) {
      req = JSON.parse(queryParams.filter);
    }
    const reqParams: any = {
      limit: queryParams.pageSize,
      offset:
        queryParams.pageNumber > 0
          ? queryParams.pageNumber * queryParams.pageSize
          : 0
    };

    if (queryParams.sortField) {
      reqParams.sortField = queryParams.sortField;
    }

    if (queryParams.sortOrder) {
      reqParams.sortOrder = queryParams.sortOrder;
    }
    // if (queryParams.pageNumber == null || queryParams.pageNumber == 0) {
    //   reqParams.offset = 0;
    // } else {
    //   reqParams.offset = parseInt(queryParams.pageNumber) + 1;
    // }
    // if (queryParams.sortOrder == '') {
    //   reqParams.sort = `!id`;
    // }
    // if (queryParams.sortOrder == 'DESC') {
    //   reqParams.sort = `!${queryParams.sortField}`;
    // }
    // if (queryParams.sortOrder == 'ASC') {
    //   reqParams.sort = `${queryParams.sortField}`;
    // }
    if (queryParams.queryString) {
      reqParams.searchString = queryParams.queryString;
    }

    if (req?.where?.isActive) {
      reqParams.isActive = `${req?.where?.isActive}`;
    }

    if (req?.where?.room_id) {
      reqParams.room_id = `${req?.where?.room_id}`;
    }
    if (req?.where?.cash_transactions) {
      reqParams.createdAt = `~${req?.where?.cash_transactions}`;
    }
    if (req?.where?.user_id) {
      reqParams.user_id = req.where.user_id;
    }
    if (req?.where?.vendor_id) {
      reqParams.vendor_id = req.where.vendor_id;
    }
    if (req?.where?.category_id) {
      reqParams.category_id = req.where.category_id;
    }
    if (req?.where?.strain_id) {
      reqParams.strain_id = req.where.strain_id;
    }
    if (req?.where?.item_type) {
      reqParams.item_type = req.where.item_type;
    }
    if (req?.where?.from_register) {
      reqParams.from_register = req.where.from_register;
    }
    if (req?.where?.facility_license_number) {
      reqParams.facility_license_number = `~${req?.where?.facility_license_number}`;
    }
    if (req?.where?.state) {
      reqParams.state = req?.where?.state;
    }

    // if (req?.where?.state_id) {
    //     reqParams.StateId = req?.where?.state_id;
    // }

    if (req?.where?.order_id) {
      reqParams.user_id = req.where.order_id;
    }
    if (req?.where?.brand_id) {
      reqParams.brand_id = req.where.brand_id;
    }
    if (req?.where?.is_delete) {
      reqParams.is_delete = req.where.is_delete;
    }
    if (req?.where?.is_active) {
      reqParams.is_active = req.where.is_active;
    }

    if (req?.where?.is_delete == false) {
      reqParams.is_delete = req.where.is_delete;
    }

    if (req?.where?.cannabis_product == false) {
      reqParams.cannabis_product = req.where.cannabis_product;
    }

    if (req?.where?.is_brand) {
      reqParams.is_brand = req.where.is_brand;
    }

    if (req?.where?.product_category_id) {
      reqParams.product_category_id = req.where.product_category_id;
    }

    if (req?.where?.vendor_id) {
      reqParams.vendor_id = req.where.vendor_id;
    }

    if (req?.where?.is_metrc_item !== undefined) {
      reqParams.is_metrc_item = req.where.is_metrc_item;
    }
    if (req?.where?.is_cannabis) {
      reqParams.is_cannabis = req.where.is_cannabis;
    }
    if (req?.where?.is_brand) {
      reqParams.is_brand = req.where.is_brand;
    }

    if (req?.where?.check_in_type) {
      reqParams.check_in_type = req.where.check_in_type;
    }

    if (req?.where?.is_finished) {
      reqParams.is_finished = req.where.is_finished;
    }

    if (req?.where?.inventory_status_name) {
      reqParams.inventory_status_name = req.where.inventory_status_name;
    }

    if (req?.where?.dispensary_id) {
      reqParams.dispensary_id = req.where.dispensary_id;
    }
    if (req?.where?.delivery_config_id) {
      reqParams.delivery_config_id = req.where.delivery_config_id;
    }

    if (req?.where?.product_category_id) {
      reqParams.product_category_id = req.where.product_category_id;
    }
    if (req?.where?.cannabis_product) {
      reqParams.cannabis_product = req.where.cannabis_product;
    }

    if (req?.where?.dispensaryName) {
      reqParams.dispensary_name = req.where.dispensaryName;
    }

    // if (req.where.is_delete !== undefined) {
    //   reqParams.is_delete = req.where.is_delete;
    // } else {
    //   reqParams.is_delete = false;
    // }
    if (req?.where?.dispensary_id) {
      reqParams.dispensary_id = req.where.dispensary_id;
    }
    if (req?.where?.varient_id) {
      reqParams.varient_id = req.where.varient_id;
    }

    if (req?.where?.product_category_id) {
      reqParams.product_category_id = req.where.product_category_id;
    }

    // if (req?.where?.createdAt) {
    //   reqParams.createdAt = req.where.createdAt
    // }
    if (req?.where?.cash_edit_dispensary_id) {
      reqParams.dispensary_id = `$in0|${req.where.cash_edit_dispensary_id}`;
    }

    if (req?.where?.cash_denomination_dispensary_id) {
      reqParams.dispensary_id = `$in${req.where.cash_denomination_dispensary_id}`;
    } else if (req?.where?.cash_denomination_dispensary_id === 0) {
      reqParams.dispensary_id = 0;
    }
    if (req?.where?.is_vault) {
      reqParams.is_vault = req.where.is_vault;
    }
    if (req?.where?.is_open) {
      reqParams.is_open = req.where.is_open;
    }
    if (req?.where?.event) {
      reqParams.event = req.where.event;
    }
    if (req?.where?.createdAt) {
      reqParams.createdAt = req?.where?.createdAt;
    }
    if (req?.where?.placed_by) {
      reqParams.placed_by = req?.where?.placed_by;
    }
    if (req?.where?.customer_id) {
      reqParams.customer_id = req?.where?.customer_id;
    }
    if (req?.where?.queueDate) {
      reqParams.createdAt = `~${req?.where?.queueDate}`;
    }
    if (req?.where?.currentDate) {
      reqParams.createdAt = `~${req?.where?.currentDate}`;
    }
    if (req?.where?.dispensaryAndAdmin) {
      reqParams.dispensary_id = `$in${req?.where?.dispensaryAndAdmin}|0`;
    }
    if (req?.where?.guest_type) {
      if (req?.where?.guest_type !== 'all') {
        const dynamicKey = req.where.guest_type; // Use the value of req.where.guest_type as the dynamic key
        reqParams[dynamicKey] = '!';
      }
    }
    if (req?.where?.to_register) {
      reqParams.to_register = req.where.to_register;
    }
    if (req?.where?.staff_id) {
      reqParams.staff_id = req.where.staff_id;
    }
    if (req?.where?.stock_quantity) {
      reqParams.stock_quantity = `>0`;
    }
    if (req?.where?.inventory_type) {
      reqParams.type = req?.where?.inventory_type;
    }
    if (req?.where?.type) {
      reqParams.type = `${req?.where?.type}`;
    }

    if (req?.where?.processFlow) {
      reqParams.processFlow = `${req?.where?.processFlow}`;
    }

    if (req?.where?.userId) {
      reqParams.userId = `${req?.where?.userId}`;
    }
    if (req?.where?.programConfigId) {
      reqParams.programConfigId = req?.where?.programConfigId;
    }
    if (req?.where?.sampleType) {
      reqParams.sampleType = `${req?.where?.sampleType}`;
    }
    if (req?.where?.hasOwnProperty('is_delete')) {
      reqParams.is_delete = req?.where?.is_delete;
    }
    if (req?.where?.hasOwnProperty('isCompany')) {
      reqParams.isCompany = req?.where?.isCompany;
    }
    if (req?.where?.companyId || req?.where?.companyId == 0) {
      reqParams.companyId = req?.where?.companyId;
    }
    if (req?.where?.paymentMethod) {
      reqParams.paymentMethod = req?.where?.paymentMethod;
    }
    if (req?.where?.formIds) {
      reqParams.formIds = req?.where?.formIds;
    }

    if (req?.where?.formType) {
      reqParams.formType = req?.where?.formType;
    }
    if (req?.where?.agreementId) {
      reqParams.agreementId = req?.where?.agreementId;
    }
    if (req?.where?.TestStatus) {
      reqParams.TestStatus = req?.where?.TestStatus;
    }
    if (req?.where?.SampleRecDate) {
      reqParams.SampleRecDate = req?.where?.SampleRecDate;
    }
    if (req?.where?.SampleSubDate) {
      reqParams.SampleSubDate = req?.where?.SampleSubDate;
    }
    if (req?.where?.TestCompletionDate) {
      reqParams.TestCompletionDate = req?.where?.TestCompletionDate;
    }
    if (req?.where?.CoProgramRole) {
      reqParams.CoProgramRole = req?.where?.CoProgramRole;
    }
    if (req?.where?.isCompleted) {
      reqParams.isCompleted = req?.where?.isCompleted;
    }
    if (req?.where?.isFieldAudit) {
      reqParams.isFieldAudit = req?.where?.isFieldAudit;
    }
    if (req?.where?.uploadToWebsite) {
      reqParams.uploadToWebsite = req?.where?.uploadToWebsite;
    }

    if (req?.where?.isCQARole) {
      reqParams.isCQA = req?.where?.isCQARole;
    }

    if (req?.where?.purposePayment) {
      reqParams.purpose = req?.where?.purposePayment;
    }
    if (req?.where?.BrandApprove) {
      reqParams.BrandApprove = req?.where?.BrandApprove;
    }

    if (req?.where?.Form02Status) {
      reqParams.Form02Status = req?.where?.Form02Status;
    }
    if (req?.where?.isIFC05) {
      reqParams.isIFC05 = req?.where?.isIFC05;
    }
    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return k + '=' + reqParams[k];
      })
      .join('&');

    return queryString;
  }

  public queryStrFormatSearch(queryParams: any) {
    var req;
    if (Object.keys(queryParams?.filter).length > 0) {
      req = JSON.parse(queryParams.filter);
    }
    const reqParams: any = {};

    if (queryParams.sortOrder == '') {
      reqParams.sort = `!id`;
    }
    if (queryParams.sortOrder == 'DESC') {
      reqParams.sort = `!${queryParams.sortField}`;
    }
    if (queryParams.sortOrder == 'ASC') {
      reqParams.sort = `${queryParams.sortField}`;
    }
    if (queryParams.queryString) {
      reqParams.searchString = queryParams.queryString;
    }

    if (req?.facility_license_number) {
      reqParams.facility_license_number = `~${req.facility_license_number}`;
    }
    if (req?.where?.varient_id) {
      reqParams.varient_id = req.where.varient_id;
    }

    if (req?.where?.dispensary_id) {
      reqParams.dispensary_id = req.where.dispensary_id;
    }
    if (req?.where?.customer_id) {
      reqParams.customer_id = req?.where?.customer_id;
    }

    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return k + '=' + reqParams[k];
      })
      .join('&');

    return queryString;
  }
  public queryStrFormat1(queryParams: any) {
    var req;
    if (Object.keys(queryParams?.filter).length > 0) {
      req = JSON.parse(queryParams.filter);
    }
    const reqParams: any = {};
    if (req?.where?.currentDate) {
      // reqParams.createdAt = '>=' + req?.where?.currentDate;
      reqParams.createdAt = `>=${req?.where?.currentDate}`;
    }
    if (req?.where?.staff_id) {
      reqParams.staff_id = req.where.staff_id;
    }
    if (req?.where?.dispensary_id) {
      reqParams.dispensary_id = req.where.dispensary_id;
    }
    if (req?.where?.is_delete == false) {
      reqParams.is_delete = req.where.is_delete;
    }
    if (req?.where?.order_id) {
      reqParams.order_id = req.where.order_id;
    }
    if (req?.where?.user_id) {
      reqParams.user_id = req.where.user_id;
    }
    if (req?.where?.room_type_id) {
      reqParams.room_type_id = req.where.room_type_id;
    }
    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return k + '=' + reqParams[k];
      })
      .join('&');

    return queryString;
  }

  onGoBack() {
    this.location.back();
  }

  //on Refresh
  private refreshList = new BehaviorSubject<boolean>(false);

  getListObservable() {
    return this.refreshList.asObservable();
  }

  triggerListRefresh() {
    this.refreshList.next(true);

    setTimeout(() => {
      this.refreshList.next(false);
    }, 300);
  }

  /**
   * getPhotos
   */
  public getMedia(queryParams: any) {
    let params = this.queryStringFormat(queryParams);
    return this.http.get('attachments?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };
        if (resp && Array.isArray(resp.data.rows)) {
          for (const item of resp.data.rows) {
            if (item) {
              if (item.file_name.split('.')[1] == 'mp4') {
                // item.isVideo = true
                item.video = environment.mediaUrl + item.dir + item.file_name;
              } else {
                // item.isImage = true
                item.image = environment.mediaUrl + item.dir + item.file_name;
              }
              retData.items.push(item);
            }
          }
          retData.totalCount = resp.data.count;
        }
        return retData;
      })
    );
  }

  public confirm(
    title?: string,
    message?: string,
    confirmLabel: string = 'OK',
    cancelLabel: string = 'Cancel',
    confirmActionColor?: any,
    iconShow?: boolean,
    iconName?: any,
    iconColor?: any,
    confirmShow?: boolean,
    cancelShow?: boolean,
    byPass?: boolean
  ): Promise<boolean> {
    // icon

    iconShow = iconShow ? iconShow : true;
    iconName = iconName ? iconName : 'heroicons_outline:exclamation';
    iconColor = iconColor ? iconColor : 'warn';

    //Confirm
    confirmShow = confirmShow == false ? false : true;
    confirmLabel = confirmLabel ? confirmLabel : 'OK';
    confirmActionColor = confirmActionColor ? confirmActionColor : 'warn';

    //Cancel
    cancelShow = cancelShow == false ? false : true;
    cancelLabel = cancelLabel ? cancelLabel : 'Cancel';

    //byPass
    byPass = byPass ? byPass : false;

    let dialogConfig: ConfirmationConfig = {
      title: title,
      message: message,
      icon: {
        show: iconShow,
        name: iconName,
        color: iconColor
      },
      actions: {
        confirm: {
          show: confirmShow,
          label: confirmLabel,
          color: confirmActionColor
        },
        cancel: {
          show: cancelShow,
          label: cancelLabel
        }
      },
      byPass: byPass,
      dismissible: true
    };
    return new Promise((resolve, reject) => {
      const modalRef = this.matDialog.open(ConfirmDialogComponent, {
        data: dialogConfig,
        autoFocus: false
      });
      modalRef.afterClosed().subscribe((result) => {
        if (!result) {
          resolve(false); // pass close
        } else {
          resolve(result); // pass confirm
        }
      });
    });
  }

  //handle error messages
  handleError(err: any) {
    if (err.message && typeof err.message === 'object') {
      const message = err.message;
      for (const key in message) {
        if (message.hasOwnProperty(key)) {
          this.toastr.error(message[key]);
        }
      }
    } else if (err.message) {
      this.toastr.error(err.message);
    }
  }

  //Color theme
  setConfig(layout: VexConfigName, colorScheme: VexColorScheme): void {
    this.configService.setConfig(layout);
    this.configService.updateConfig({
      style: {
        colorScheme
      }
    });
  }
  postAttachment(data: any) {
    return this.http.post('attachment', data).pipe(
      map(
        (resp: any) => {
          return resp.data || null;
        },
        (err: any) => {}
      )
    );
  }
  // convert innerhtml tags to text
  stripHtmlTags(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  public exportCSV(rows: any, fileName?: any) {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      // showTitle: true,
      // title: fileName,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: fileName
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(rows);
  }

  viewForm(form: any) {
    let dialogRef: MatDialogRef<ViewPdfComponent>;
    dialogRef = this.dialog.open(ViewPdfComponent, {
      width: '80%',
      data: {
        src: form
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {});
  }

  formatToUSD(value: number): string {
    // Format the number as a currency string in USD format
    return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} USD`;
  }
  formatToUSDWithoutFr(value: string): string {
    // Format the number as a currency string in USD format
    return `${value.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  }

  async flattenPdf(blob: Blob): Promise<Blob> {
    // Convert the Blob into ArrayBuffer
    const arrayBuffer = await blob.arrayBuffer();

    // Load the existing PDF document
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    // Get all form fields (if there are any)
    const form = pdfDoc.getForm();
    if (form) {
      // Flatten the form fields into static text
      form.flatten();
    }

    // Serialize the PDFDocument to bytes (Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Create a new Blob with the flattened content
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }

  public async updatePdfFormFields(
    pdfUrl: string,
    formData: Object
  ): Promise<any> {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/pdf' // Ensure this header is appropriate
      });

      // Fetch the existing PDF document
      const existingPdfBytes: any = await this.http
        .get(pdfUrl, {
          headers,
          responseType: 'arraybuffer'
        })
        .toPromise(); // Use toPromise for cleaner async/await handling

      // Load the PDF document
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Get the form fields
      const form: any = pdfDoc.getForm();

      // Update form fields
      for (const [fieldName, fieldValue] of Object.entries(formData)) {
        try {
          const field: any = form.getField(fieldName);

          if (field) {
            if (field instanceof PDFTextField) {
              if (
                typeof fieldValue === 'string' ||
                typeof fieldValue === 'undefined'
              ) {
                field.setText(fieldValue ?? ''); // Use empty string if fieldValue is undefined
              } else {
                // console.warn(
                //   `Field value for "${fieldName}" should be a string or undefined.`
                // );
              }
            } else if (field.constructor.name === 'PDFCheckbox') {
              // Example: Check if the value should be checked
              if (fieldValue === true || fieldValue === 'true') {
                field.check();
              } else {
                field.uncheck();
              }
            } else if (field.constructor.name === 'PDFRadioButton') {
              // Handle radio buttons if necessary
              // Example: set the selected radio button based on fieldValue
            } else {
              // console.warn(`Field "${fieldName}" is of unsupported type.`);
            }
          } else {
            // console.warn(
            //   `Field with name "${fieldName}" not found in the PDF.`
            // );
          }
        } catch (error) {
          // console.error(`Error processing field "${fieldName}":`, error);
        }
      }

      // Serialize the PDF document to bytes
      const pdfBytes = await pdfDoc.save();

      // Create a Blob and create an object URL
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      return blob;
      // const url = URL.createObjectURL(blob);
      // // Open the PDF in a new tab
      // window.open(url, '_blank');
      // // Clean up the object URL after use
      // URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error updating PDF form fields:', error);
    }
  }
}

export interface UserSettingsInterface {
  id: number;
  name: string;
  email: string;
  isVerified: boolean;
  isActive: boolean;
  role_id: number;
}
export class QueryParamsModel {
  // fields
  filter: any;
  queryString: string;
  sortOrder: string; // asc || desc
  sortField: string;
  pageNumber: number;
  pageSize: number;

  // constructor overrides
  constructor(
    _filter: any,
    _sortOrder: string = 'asc',
    _sortField: string = '',
    _pageNumber: number = 0,
    _pageSize: number = 10,
    _queryString: string = ''
  ) {
    this.filter = _filter;
    this.queryString = _queryString;
    this.sortOrder = _sortOrder;
    this.sortField = _sortField;
    this.pageNumber = _pageNumber;
    this.pageSize = _pageSize;
  }
}

export interface ConfirmationConfig {
  title?: string;
  message?: string;
  icon?: {
    show?: boolean;
    name?: string;
    color?:
      | 'primary'
      | 'accent'
      | 'warn'
      | 'basic'
      | 'info'
      | 'success'
      | 'warning'
      | 'error';
  };
  actions?: {
    confirm?: {
      show?: boolean;
      label?: string;
      color?: 'primary' | 'accent' | 'warn';
    };
    cancel?: {
      show?: boolean;
      label?: string;
    };
  };
  byPass?: boolean;
  dismissible?: boolean;
}
