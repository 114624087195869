import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { Router, RouterLink } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  BehaviorSubject,
  Subscription,
  distinctUntilChanged,
  filter,
  firstValueFrom
} from 'rxjs';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { CommonModule } from '@angular/common';
import { CartManager } from './cart-manager';
import { CartService } from './cart.service';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { UserAuthenticationService } from 'src/app/core/navigation/user-authantication.service';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'vex-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  standalone: true,
  imports: [
    MatDividerModule,
    MatListModule,
    RouterLink,
    MatRippleModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    CommonModule
  ]
})
export class cartComponent implements OnInit {
  cartData: any;
  subTotal: number;
  processingFee: number;
  taxes: number;
  total: number;

  userInfo: any;
  programConfigId: any;
  programDetails: any;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  constructor(
    private router: Router,
    private readonly layoutService: VexLayoutService,
    private cartManager: CartManager,
    private cartService: CartService,
    private appSettingsService: AppSettingsService,
    public userCheck: UserAuthenticationService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService
  ) {}

  async ngOnInit() {
    this.userInfo = this.appSettingsService.userData().userDetails;
    try {
      let programInfo = await firstValueFrom(this.dataService.getUserData());
      this.dataService
        .getProgramCartObservable()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async (res) => {
          if (res) {
            let programInfo = await firstValueFrom(
              this.dataService.getUserData()
            );
            if (programInfo && programInfo?.programConfigId !== null) {
              this.getCartData(programInfo, programInfo?.programConfigId);
              this.cdr.detectChanges();
            }
          }
        });
      if (programInfo?.programConfigId) {
        this.programConfigId = programInfo?.programConfigId;
      }
    } catch (err) {
      console.error(err);
    }
  }

  get cartDataGetter() {
    return this.cartData;
  }
  licenseAgreementCart: boolean = false;
  async getCartData(programInfo: any, programConfigId: number) {
    // const cartDataString = localStorage.getItem('cart');
    // if (cartDataString) {
    //   this.cartData = JSON.parse(cartDataString);
    //   this.cartManager.calculateTotal();
    // }
    let params: any = {
      userID: this.userInfo.id
    };
    if (!programConfigId) return;
    this.programDetails = programInfo;
    if (
      !programInfo?.isPaid &&
      programInfo?.isFinalized &&
      !programInfo?.isOrdered
    ) {
      this.licenseAgreementCart = true;
      let data = {
        id: 303,
        userID: this.userInfo.id,
        companyID: programInfo.companyId,
        totalPrice: programInfo.licenseFee,
        tax: 0,
        processingFee: 0,
        programConfigID: programInfo.programConfigId,
        quotationId: null,
        cartItems: [
          {
            class: 'LicenseAgreement',
            name: `${programInfo.programName} Annual License`
          }
        ]
      };
      this.cartData = data;
      this.cartManager.cartData = data;
      return;
    }

    try {
      let cartResp = await firstValueFrom(
        this.cartService.getCart(this.userInfo.id, programConfigId)
      );
      this.cartData = cartResp;
      this.cartManager.cartData = cartResp;

      this.cdr.detectChanges();
      this.dataService.triggerProgramCartCountRefresh();
    } catch (err: any) {
      this.cartService.cartItemCount = 0;
      this.cartData = null;
      this.dataService.triggerProgramCartCountRefresh();
    }
  }

  async deleteCart(item: any) {
    // this.cartManager.deleteCart(item);
    this.appSettingsService
      .confirm('Delete', 'Are you sure you want to delete?')
      .then(async (res) => {
        if (res) {
          let itemId = item.id;
          try {
            let cartResp = await firstValueFrom(
              this.cartService.deleteCartItem(this.cartData.id, itemId)
            );
            this.ngOnInit();
            this.cartService.triggerListRefresh();
          } catch (err: any) {
            throw new Error(err);
          }
        }
      });
  }

  checkout() {
    if (this.licenseAgreementCart) {
      if (this.programDetails.quotationId) {
        this.router.navigate(['/home', 1], {
          queryParams: { quotationNumber: this.programDetails.id }
        });
      } else {
        this.router.navigate(['/home', 1]);
      }

      this.layoutService.closeCart();
    } else {
      this.router.navigate(['/checkout']);
      this.layoutService.closeCart();
    }
  }
  cancel() {
    this.layoutService.closeCart();
  }

  async onClearCart() {
    try {
      this.appSettingsService
        .confirm('Delete', 'Are you sure you want to delete?')
        .then(async (res) => {
          if (res) {
            let cartResp = await firstValueFrom(
              this.cartService.deleteCart(
                this.cartData.id,
                this.programConfigId
              )
            );
            this.dataService.triggerProgramCartRefresh();
          }
        });
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
