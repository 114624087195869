<!-- grid-settings.component.html -->
<div class="flex flex-col max-h-screen">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
    <div class="text-lg font-medium">Grid Settings</div>
  </div>

  <div class="px-12">
    <ul style="list-style-type: disc">
      <li>Drag columns to rearrange their order in grid</li>
      <li>Use the switches to toggle columns shown and hidden</li>
    </ul>
  </div>

  <!-- <mat-list
    class="cursor-pointer"
    cdkDropList
    (cdkDropListDropped)="drop($event)">
    <mat-list-item *ngFor="let column of columns" cdkDrag>
      <div
        class="flex justify-between items-center"
        (click)="toggleColumnVisibility(column)">
        <div>
          <mat-checkbox [(ngModel)]="column.visible" color="primary">{{
            column.label | titlecase
          }}</mat-checkbox>
        </div>
        <div>
          <mat-icon svgIcon="mat:drag_handle">drag_handle</mat-icon>
        </div>
      </div>
    </mat-list-item>
  </mat-list> -->

  <ng-container *ngIf="groupColumns && groupColumns?.length > 0">
    <mat-accordion class="example-headers p-4">
      <ng-container *ngFor="let tableGroup of groupColumns">
        <mat-expansion-panel
          class="my-3"
          [(expanded)]="tableGroup.isExpanded"
          *ngIf="tableGroup && tableGroup?.columns.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title
              class="flex items-center justify-between w-full m-0">
              {{ tableGroup.tableName | titlecase }}
              <mat-icon
                [svgIcon]="
                  tableGroup.isExpanded ? 'mat:expand_less' : 'mat:expand_more'
                "></mat-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- <div *ngFor="let column of tableGroup?.columns">
            <mat-checkbox [(ngModel)]="column.visible" color="primary">
              {{ column.label | titlecase }}
            </mat-checkbox> 
          </div>-->

          <mat-list
            class="cursor-pointer"
            cdkDropList
            (cdkDropListDropped)="drop($event)">
            <mat-list-item *ngFor="let column of tableGroup?.columns" cdkDrag>
              <div
                class="flex justify-between items-center"
                (click)="toggleColumnVisibility(column)">
                <div>
                  <mat-checkbox [(ngModel)]="column.visible" color="primary">{{
                    column.label | titlecase
                  }}</mat-checkbox>
                </div>
                <div>
                  <mat-icon svgIcon="mat:drag_handle">drag_handle</mat-icon>
                </div>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </ng-container>
</div>
