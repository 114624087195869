// loader.component.ts
import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from 'src/app/core/shared/shared.module';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  imports: [NgIf, MatProgressSpinnerModule, SharedModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input() loading: boolean = true;

  constructor(private cdr: ChangeDetectorRef) {}

  // Detect changes when loading input is updated
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loading']) {
      this.cdr.markForCheck(); // Manually trigger change detection
    }
  }
}
