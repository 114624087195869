import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListRefreshService {
  private refreshList = new BehaviorSubject<boolean>(false);

  constructor() { }

  getListObservable(): Observable<boolean> {
    return this.refreshList.asObservable();
  }

  triggerListRefresh(): void {
    this.refreshList.next(true);

    setTimeout(() => {
      this.refreshList.next(false);
    }, 300);
  }
}
