// The file for the current environment will overwrite this one during build.
// Different environments can be found in ./environment.{dev|prod}.ts, and
// you can create your own and use it with the --env flag.
// The build system defaults to the dev environment.

export const environment = {
  production: true,
  apiUrl: 'https://dev-api.fluidquality.com/api/v1/',
  // apiUrl: 'http://localhost:3000/api/v1/',
  mediaUrl: 'https://cdn.fluidquality.com'
};
