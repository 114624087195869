import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedModule } from '../shared.module';
import * as moment from 'moment';

@Component({
  selector: 'vex-date-input-filter',
  templateUrl: './date-input-filter.component.html',
  styleUrls: ['./date-input-filter.component.scss'],
  standalone: true,
  imports: [SharedModule]
})
export class DateInputFilterComponent implements OnInit {
  @Input() formData: any;
  @Output() formValuesChanged = new EventEmitter<any>();

  ngOnInit() {
    this.formData.forEach((item: any) => {
      item.formGroup.valueChanges.subscribe((value: any) => {
        this.onFormValueChange(item.formName, value);
      });
    });
  }

  onFormValueChange(formName: string, value: any) {
    let start = value.start;
    let end = value.end;
    if (start && end) {
      let formValue = { formName, start, end };
      this.formValuesChanged.emit(formValue);
    }
  }
}
